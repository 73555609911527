<template>
  <v-app>
    <div>
      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        style="background-color: #00b0ff; border: none"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg svg-icon-white">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text text-white">
          Laporan <b>Pengunjung</b>
        </div>
      </b-alert>
      <div class="row">
        <div class="col-12">
          <div class="card py-4 px-4 mb-8">
            <div class="row justify-content-between">
              <div class="col-6">
                <div class="row justify-content-between">
              <div class="col-md-6">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="filter.year"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-form-input
                      id="input-month"
                      v-model="filter.year"
                      placeholder="Pilih Tahun"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></b-form-input>
                  </template>
                  <v-date-picker
                    locale="id-ID"
                    v-model="filter.year"
                    ref="picker"
                    type="month"
                    scrollable
                    :active-picker.sync="activePicker"
                    @click:year="saveYear(year)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="filterByMonth">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
              <div class="col-md-6">
                <treeselect
                  v-if="
                    currentUser.role_key_name == 'super_admin' ||
                    currentUser.user_type_id == 3
                  "
                  v-model="filter.posyandu_id"
                  :multiple="false"
                  placeholder="Pilih Posyandu"
                  :options="posyandu_list"
                  :disabled="chooseYear ? false : true"
                  @input="filterByPosyandu"
                />
              </div>
                </div>
              </div>
              <div class="col-md-1">
                <b-button
                  squared
                  size="sm"
                  variant="success"
                  @click="btnPrintOnClick"
                  v-b-tooltip.hover.right
                  title="Print"
                  :disabled="buttonPrint ? false : true"
                  ><i class="fas fa-print px-0"></i
                ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Begin::Laporan -->
      <div class="row" id="printMe" v-if="dataLoaded">
        <div class="col-12 px-1">
          <div class="card" style="font-size: 10px; border: none">
            <div class="card-body">
              <div class="d-flex justify-content-center">
                <h6 style="font-weight: bold">
                  JUMLAH PENGUNJUNG / JUMLAH PETUGAS POSYANDU / JUMLAH BAYI
                  LAHIR MENINGGAL
                </h6>
              </div>
              <div class="d-flex justify-content-center mt-2">
                <table class="col-2" style="white-space: nowrap; border: none">
                  <tr>
                    <th class="py-0">POSYANDU</th>
                    <th class="px-2 py-0">:</th>
                    <th class="py-0 text-uppercase">{{ posyandu.name }}</th>
                  </tr>
                  <tr>
                    <th class="py-0">DESA / KELURAHAN</th>
                    <th class="py-0 px-2">:</th>
                    <th class="py-0 text-uppercase">{{ posyandu.ward }}</th>
                  </tr>
                  <tr>
                    <th class="py-0">KECAMATAN</th>
                    <th class="py-0 px-2">:</th>
                    <th class="py-0 text-uppercase">
                      {{ posyandu.districts }}
                    </th>
                  </tr>
                  <tr>
                    <th class="py-0">BULAN / TAHUN</th>
                    <th class="py-0 px-2">:</th>
                    <th class="py-0 text-uppercase">{{ setCurrentTime() }}</th>
                  </tr>
                </table>
              </div>

              <div class="row justify-content-start">
                <div class="col-12">
                  <table
                    border="1"
                    class="table table-bordered text-center"
                    :class="{'table-responsive': printOut}"
                    style="font-size: 10px"
                  >
                    <tr>
                      <th style="vertical-align: middle" rowspan="5">NO</th>
                      <th style="vertical-align: middle" rowspan="5">BULAN</th>
                      <th colspan="12">JUMLAH PENGUNJUNG</th>
                      <th colspan="6">JUMLAH PETUGAS YANG HADIR</th>
                      <th colspan="4">JUMLAH BAYI</th>
                      <th style="vertical-align: middle" rowspan="5" :width="printOut ? '18%' : ''">
                        KETERANGAN
                      </th>
                    </tr>
                    <tr>
                      <th colspan="8">BALITA</th>
                      <th style="vertical-align: middle" rowspan="4">WUS</th>
                      <th colspan="3">IBU</th>
                      <th
                        colspan="2"
                        rowspan="3"
                        style="vertical-align: middle"
                      >
                        KADER
                      </th>
                      <th
                        colspan="2"
                        rowspan="3"
                        style="vertical-align: middle"
                      >
                        PLKB
                      </th>
                      <th
                        colspan="2"
                        rowspan="3"
                        style="vertical-align: middle"
                      >
                        MEDIS DAN PARAMEDIS
                      </th>
                      <th
                        colspan="2"
                        rowspan="3"
                        style="vertical-align: middle"
                      >
                        YANG LAHIR
                      </th>
                      <th
                        colspan="2"
                        rowspan="3"
                        style="vertical-align: middle"
                      >
                        MENINGGAL
                      </th>
                    </tr>
                    <tr>
                      <th colspan="4">0-12 BLN</th>
                      <th colspan="4">1-5 TH</th>
                      <th rowspan="3" style="vertical-align: middle">PUS</th>
                      <th rowspan="3" style="vertical-align: middle">HAMIL</th>
                      <th rowspan="3" style="vertical-align: middle">
                        MENYUSUI
                      </th>
                    </tr>
                    <tr>
                      <th colspan="2">BARU</th>
                      <th colspan="2">LAMA</th>
                      <th colspan="2">BARU</th>
                      <th colspan="2">LAMA</th>
                    </tr>
                    <tr>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                    </tr>
                    <tr>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                      <th>13</th>
                      <th>14</th>
                      <th>15</th>
                      <th>16</th>
                      <th>17</th>
                      <th>18</th>
                      <th>19</th>
                      <th>20</th>
                      <th>21</th>
                      <th>22</th>
                      <th>23</th>
                      <th>24</th>
                      <th>25</th>
                    </tr>
                    <tr>
                      <th>1</th>
                      <th>Januari</th>
                      <th>{{ januari1.column3 }}</th>
                      <th>{{ januari1.column4 }}</th>
                      <th>{{ januari1.column5 }}</th>
                      <th>{{ januari1.column6 }}</th>
                      <th>{{ januari1.column7 }}</th>
                      <th>{{ januari1.column8 }}</th>
                      <th>{{ januari1.column9 }}</th>
                      <th>{{ januari1.column10 }}</th>
                      <th>{{ januari1.column11 }}</th>
                      <th>{{ januari1.column12 }}</th>
                      <th>{{ januari1.column13 }}</th>
                      <th>{{ januari1.column14 }}</th>
                      <th>{{ januari1.column15 }}</th>
                      <th>{{ januari1.column16 }}</th>
                      <th>{{ januari1.column17 }}</th>
                      <th>{{ januari1.column18 }}</th>
                      <th>{{ januari1.column19 }}</th>
                      <th>{{ januari1.column20 }}</th>
                      <th>{{ januari1.column21 }}</th>
                      <th>{{ januari1.column22 }}</th>
                      <th>{{ januari1.column23 }}</th>
                      <th>{{ januari1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>2</th>
                      <th>Februari</th>
                      <th>{{ februari1.column3 }}</th>
                      <th>{{ februari1.column4 }}</th>
                      <th>{{ februari1.column5 }}</th>
                      <th>{{ februari1.column6 }}</th>
                      <th>{{ februari1.column7 }}</th>
                      <th>{{ februari1.column8 }}</th>
                      <th>{{ februari1.column9 }}</th>
                      <th>{{ februari1.column10 }}</th>
                      <th>{{ februari1.column11 }}</th>
                      <th>{{ februari1.column12 }}</th>
                      <th>{{ februari1.column13 }}</th>
                      <th>{{ februari1.column14 }}</th>
                      <th>{{ februari1.column15 }}</th>
                      <th>{{ februari1.column16 }}</th>
                      <th>{{ februari1.column17 }}</th>
                      <th>{{ februari1.column18 }}</th>
                      <th>{{ februari1.column19 }}</th>
                      <th>{{ februari1.column20 }}</th>
                      <th>{{ februari1.column21 }}</th>
                      <th>{{ februari1.column22 }}</th>
                      <th>{{ februari1.column23 }}</th>
                      <th>{{ februari1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>3</th>
                      <th>Maret</th>
                      <th>{{ maret1.column3 }}</th>
                      <th>{{ maret1.column4 }}</th>
                      <th>{{ maret1.column5 }}</th>
                      <th>{{ maret1.column6 }}</th>
                      <th>{{ maret1.column7 }}</th>
                      <th>{{ maret1.column8 }}</th>
                      <th>{{ maret1.column9 }}</th>
                      <th>{{ maret1.column10 }}</th>
                      <th>{{ maret1.column11 }}</th>
                      <th>{{ maret1.column12 }}</th>
                      <th>{{ maret1.column13 }}</th>
                      <th>{{ maret1.column14 }}</th>
                      <th>{{ maret1.column15 }}</th>
                      <th>{{ maret1.column16 }}</th>
                      <th>{{ maret1.column17 }}</th>
                      <th>{{ maret1.column18 }}</th>
                      <th>{{ maret1.column19 }}</th>
                      <th>{{ maret1.column20 }}</th>
                      <th>{{ maret1.column21 }}</th>
                      <th>{{ maret1.column22 }}</th>
                      <th>{{ maret1.column23 }}</th>
                      <th>{{ maret1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>4</th>
                      <th>April</th>
                      <th>{{ april1.column3 }}</th>
                      <th>{{ april1.column4 }}</th>
                      <th>{{ april1.column5 }}</th>
                      <th>{{ april1.column6 }}</th>
                      <th>{{ april1.column7 }}</th>
                      <th>{{ april1.column8 }}</th>
                      <th>{{ april1.column9 }}</th>
                      <th>{{ april1.column10 }}</th>
                      <th>{{ april1.column11 }}</th>
                      <th>{{ april1.column12 }}</th>
                      <th>{{ april1.column13 }}</th>
                      <th>{{ april1.column14 }}</th>
                      <th>{{ april1.column15 }}</th>
                      <th>{{ april1.column16 }}</th>
                      <th>{{ april1.column17 }}</th>
                      <th>{{ april1.column18 }}</th>
                      <th>{{ april1.column19 }}</th>
                      <th>{{ april1.column20 }}</th>
                      <th>{{ april1.column21 }}</th>
                      <th>{{ april1.column22 }}</th>
                      <th>{{ april1.column23 }}</th>
                      <th>{{ april1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>5</th>
                      <th>Mei</th>
                      <th>{{ mei1.column3 }}</th>
                      <th>{{ mei1.column4 }}</th>
                      <th>{{ mei1.column5 }}</th>
                      <th>{{ mei1.column6 }}</th>
                      <th>{{ mei1.column7 }}</th>
                      <th>{{ mei1.column8 }}</th>
                      <th>{{ mei1.column9 }}</th>
                      <th>{{ mei1.column10 }}</th>
                      <th>{{ mei1.column11 }}</th>
                      <th>{{ mei1.column12 }}</th>
                      <th>{{ mei1.column13 }}</th>
                      <th>{{ mei1.column14 }}</th>
                      <th>{{ mei1.column15 }}</th>
                      <th>{{ mei1.column16 }}</th>
                      <th>{{ mei1.column17 }}</th>
                      <th>{{ mei1.column18 }}</th>
                      <th>{{ mei1.column19 }}</th>
                      <th>{{ mei1.column20 }}</th>
                      <th>{{ mei1.column21 }}</th>
                      <th>{{ mei1.column22 }}</th>
                      <th>{{ mei1.column23 }}</th>
                      <th>{{ mei1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>6</th>
                      <th>Juni</th>
                      <th>{{ juni1.column3 }}</th>
                      <th>{{ juni1.column4 }}</th>
                      <th>{{ juni1.column5 }}</th>
                      <th>{{ juni1.column6 }}</th>
                      <th>{{ juni1.column7 }}</th>
                      <th>{{ juni1.column8 }}</th>
                      <th>{{ juni1.column9 }}</th>
                      <th>{{ juni1.column10 }}</th>
                      <th>{{ juni1.column11 }}</th>
                      <th>{{ juni1.column12 }}</th>
                      <th>{{ juni1.column13 }}</th>
                      <th>{{ juni1.column14 }}</th>
                      <th>{{ juni1.column15 }}</th>
                      <th>{{ juni1.column16 }}</th>
                      <th>{{ juni1.column17 }}</th>
                      <th>{{ juni1.column18 }}</th>
                      <th>{{ juni1.column19 }}</th>
                      <th>{{ juni1.column20 }}</th>
                      <th>{{ juni1.column21 }}</th>
                      <th>{{ juni1.column22 }}</th>
                      <th>{{ juni1.column23 }}</th>
                      <th>{{ juni1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>7</th>
                      <th>Juli</th>
                      <th>{{ juli1.column3 }}</th>
                      <th>{{ juli1.column4 }}</th>
                      <th>{{ juli1.column5 }}</th>
                      <th>{{ juli1.column6 }}</th>
                      <th>{{ juli1.column7 }}</th>
                      <th>{{ juli1.column8 }}</th>
                      <th>{{ juli1.column9 }}</th>
                      <th>{{ juli1.column10 }}</th>
                      <th>{{ juli1.column11 }}</th>
                      <th>{{ juli1.column12 }}</th>
                      <th>{{ juli1.column13 }}</th>
                      <th>{{ juli1.column14 }}</th>
                      <th>{{ juli1.column15 }}</th>
                      <th>{{ juli1.column16 }}</th>
                      <th>{{ juli1.column17 }}</th>
                      <th>{{ juli1.column18 }}</th>
                      <th>{{ juli1.column19 }}</th>
                      <th>{{ juli1.column20 }}</th>
                      <th>{{ juli1.column21 }}</th>
                      <th>{{ juli1.column22 }}</th>
                      <th>{{ juli1.column23 }}</th>
                      <th>{{ juli1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>8</th>
                      <th>Agustus</th>
                      <th>{{ agustus1.column3 }}</th>
                      <th>{{ agustus1.column4 }}</th>
                      <th>{{ agustus1.column5 }}</th>
                      <th>{{ agustus1.column6 }}</th>
                      <th>{{ agustus1.column7 }}</th>
                      <th>{{ agustus1.column8 }}</th>
                      <th>{{ agustus1.column9 }}</th>
                      <th>{{ agustus1.column10 }}</th>
                      <th>{{ agustus1.column11 }}</th>
                      <th>{{ agustus1.column12 }}</th>
                      <th>{{ agustus1.column13 }}</th>
                      <th>{{ agustus1.column14 }}</th>
                      <th>{{ agustus1.column15 }}</th>
                      <th>{{ agustus1.column16 }}</th>
                      <th>{{ agustus1.column17 }}</th>
                      <th>{{ agustus1.column18 }}</th>
                      <th>{{ agustus1.column19 }}</th>
                      <th>{{ agustus1.column20 }}</th>
                      <th>{{ agustus1.column21 }}</th>
                      <th>{{ agustus1.column22 }}</th>
                      <th>{{ agustus1.column23 }}</th>
                      <th>{{ agustus1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>9</th>
                      <th>September</th>
                      <th>{{ september1.column3 }}</th>
                      <th>{{ september1.column4 }}</th>
                      <th>{{ september1.column5 }}</th>
                      <th>{{ september1.column6 }}</th>
                      <th>{{ september1.column7 }}</th>
                      <th>{{ september1.column8 }}</th>
                      <th>{{ september1.column9 }}</th>
                      <th>{{ september1.column10 }}</th>
                      <th>{{ september1.column11 }}</th>
                      <th>{{ september1.column12 }}</th>
                      <th>{{ september1.column13 }}</th>
                      <th>{{ september1.column14 }}</th>
                      <th>{{ september1.column15 }}</th>
                      <th>{{ september1.column16 }}</th>
                      <th>{{ september1.column17 }}</th>
                      <th>{{ september1.column18 }}</th>
                      <th>{{ september1.column19 }}</th>
                      <th>{{ september1.column20 }}</th>
                      <th>{{ september1.column21 }}</th>
                      <th>{{ september1.column22 }}</th>
                      <th>{{ september1.column23 }}</th>
                      <th>{{ september1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>10</th>
                      <th>Oktober</th>
                      <th>{{ oktober1.column3 }}</th>
                      <th>{{ oktober1.column4 }}</th>
                      <th>{{ oktober1.column5 }}</th>
                      <th>{{ oktober1.column6 }}</th>
                      <th>{{ oktober1.column7 }}</th>
                      <th>{{ oktober1.column8 }}</th>
                      <th>{{ oktober1.column9 }}</th>
                      <th>{{ oktober1.column10 }}</th>
                      <th>{{ oktober1.column11 }}</th>
                      <th>{{ oktober1.column12 }}</th>
                      <th>{{ oktober1.column13 }}</th>
                      <th>{{ oktober1.column14 }}</th>
                      <th>{{ oktober1.column15 }}</th>
                      <th>{{ oktober1.column16 }}</th>
                      <th>{{ oktober1.column17 }}</th>
                      <th>{{ oktober1.column18 }}</th>
                      <th>{{ oktober1.column19 }}</th>
                      <th>{{ oktober1.column20 }}</th>
                      <th>{{ oktober1.column21 }}</th>
                      <th>{{ oktober1.column22 }}</th>
                      <th>{{ oktober1.column23 }}</th>
                      <th>{{ oktober1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>11</th>
                      <th>November</th>
                      <th>{{ november1.column3 }}</th>
                      <th>{{ november1.column4 }}</th>
                      <th>{{ november1.column5 }}</th>
                      <th>{{ november1.column6 }}</th>
                      <th>{{ november1.column7 }}</th>
                      <th>{{ november1.column8 }}</th>
                      <th>{{ november1.column9 }}</th>
                      <th>{{ november1.column10 }}</th>
                      <th>{{ november1.column11 }}</th>
                      <th>{{ november1.column12 }}</th>
                      <th>{{ november1.column13 }}</th>
                      <th>{{ november1.column14 }}</th>
                      <th>{{ november1.column15 }}</th>
                      <th>{{ november1.column16 }}</th>
                      <th>{{ november1.column17 }}</th>
                      <th>{{ november1.column18 }}</th>
                      <th>{{ november1.column19 }}</th>
                      <th>{{ november1.column20 }}</th>
                      <th>{{ november1.column21 }}</th>
                      <th>{{ november1.column22 }}</th>
                      <th>{{ november1.column23 }}</th>
                      <th>{{ november1.column24 }}</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>12</th>
                      <th>Desember</th>
                      <th>{{ desember1.column3 }}</th>
                      <th>{{ desember1.column4 }}</th>
                      <th>{{ desember1.column5 }}</th>
                      <th>{{ desember1.column6 }}</th>
                      <th>{{ desember1.column7 }}</th>
                      <th>{{ desember1.column8 }}</th>
                      <th>{{ desember1.column9 }}</th>
                      <th>{{ desember1.column10 }}</th>
                      <th>{{ desember1.column11 }}</th>
                      <th>{{ desember1.column12 }}</th>
                      <th>{{ desember1.column13 }}</th>
                      <th>{{ desember1.column14 }}</th>
                      <th>{{ desember1.column15 }}</th>
                      <th>{{ desember1.column16 }}</th>
                      <th>{{ desember1.column17 }}</th>
                      <th>{{ desember1.column18 }}</th>
                      <th>{{ desember1.column19 }}</th>
                      <th>{{ desember1.column20 }}</th>
                      <th>{{ desember1.column21 }}</th>
                      <th>{{ desember1.column22 }}</th>
                      <th>{{ desember1.column23 }}</th>
                      <th>{{ desember1.column24 }}</th>
                      <th></th>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <span style="font-weight: bold">DATA KEGIATAN POSYANDU</span>
              </div>
              <div class="row justify-content-start">
                <div class="col-12">
                  <table
                    border="1"
                    class="table table-bordered text-center"
                    style="font-size: 10px"
                    :class="{'table-responsive': printOut}"
                  >
                    <tr>
                      <th style="vertical-align: middle" rowspan="3">NO</th>
                      <th style="vertical-align: middle" rowspan="3">BULAN</th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="3"
                      >
                        JML IBU HAMIL
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="3"
                      >
                        DIPERIKSA
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="3"
                      >
                        PETAB (TABLET RESI)
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="3"
                      >
                        JML IBU MENYUSUI
                      </th>
                      <th colspan="8">JUMLAH AKSEPTOR</th>
                      <th colspan="12">PENIMBANGAN BALITA</th>
                    </tr>
                    <tr>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="2"
                      >
                        KONDOM
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="2"
                      >
                        PIL
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="2"
                      >
                        IMPLAN
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="2"
                      >
                        MOP
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="2"
                      >
                        MOW
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="2"
                      >
                        IUD
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="2"
                      >
                        SUNTIKAN
                      </th>
                      <th
                        style="
                          vertical-align: middle;
                          writing-mode: vertical-rl;
                        "
                        rowspan="2"
                      >
                        LAIN-LAIN
                      </th>
                      <th colspan="2">JUMLAH BALITA (S)</th>
                      <th colspan="2">JML BALITA YANG MEMILIKI KMS (S)</th>
                      <th colspan="2">JML BALITA YANG DITIMBANG (D)</th>
                      <th colspan="2">JML BALITA YANG NAIK (D)</th>
                      <th colspan="2">JML MENDAPAT VIT. A (D)</th>
                      <th colspan="2">JML MENDAPAT PMT (D)</th>
                    </tr>
                    <tr>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                      <th>L</th>
                      <th>P</th>
                    </tr>
                    <tr>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                      <th>13</th>
                      <th>14</th>
                      <th>15</th>
                      <th>16</th>
                      <th>17</th>
                      <th>18</th>
                      <th>19</th>
                      <th>20</th>
                      <th>21</th>
                      <th>22</th>
                      <th>23</th>
                      <th>24</th>
                      <th>25</th>
                      <th>26</th>
                    </tr>
                    <tr>
                      <th>1</th>
                      <th>Januari</th>
                      <th>{{ januari2.column3 }}</th>
                      <th>{{ januari2.column4 }}</th>
                      <th>{{ januari2.column5 }}</th>
                      <th>{{ januari2.column6 }}</th>
                      <th>{{ januari2.column7 }}</th>
                      <th>{{ januari2.column8 }}</th>
                      <th>{{ januari2.column9 }}</th>
                      <th>{{ januari2.column10 }}</th>
                      <th>{{ januari2.column11 }}</th>
                      <th>{{ januari2.column12 }}</th>
                      <th>{{ januari2.column13 }}</th>
                      <th>{{ januari2.column14 }}</th>
                      <th>{{ januari2.column15 }}</th>
                      <th>{{ januari2.column16 }}</th>
                      <th>{{ januari2.column17 }}</th>
                      <th>{{ januari2.column18 }}</th>
                      <th>{{ januari2.column19 }}</th>
                      <th>{{ januari2.column20 }}</th>
                      <th>{{ januari2.column21 }}</th>
                      <th>{{ januari2.column22 }}</th>
                      <th>{{ januari2.column23 }}</th>
                      <th>{{ januari2.column24 }}</th>
                      <th>{{ januari2.column25 }}</th>
                      <th>{{ januari2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>2</th>
                      <th>Februari</th>
                      <th>{{ februari2.column3 }}</th>
                      <th>{{ februari2.column4 }}</th>
                      <th>{{ februari2.column5 }}</th>
                      <th>{{ februari2.column6 }}</th>
                      <th>{{ februari2.column7 }}</th>
                      <th>{{ februari2.column8 }}</th>
                      <th>{{ februari2.column9 }}</th>
                      <th>{{ februari2.column10 }}</th>
                      <th>{{ februari2.column11 }}</th>
                      <th>{{ februari2.column12 }}</th>
                      <th>{{ februari2.column13 }}</th>
                      <th>{{ februari2.column14 }}</th>
                      <th>{{ februari2.column15 }}</th>
                      <th>{{ februari2.column16 }}</th>
                      <th>{{ februari2.column17 }}</th>
                      <th>{{ februari2.column18 }}</th>
                      <th>{{ februari2.column19 }}</th>
                      <th>{{ februari2.column20 }}</th>
                      <th>{{ februari2.column21 }}</th>
                      <th>{{ februari2.column22 }}</th>
                      <th>{{ februari2.column23 }}</th>
                      <th>{{ februari2.column24 }}</th>
                      <th>{{ februari2.column25 }}</th>
                      <th>{{ februari2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>3</th>
                      <th>Maret</th>
                      <th>{{ maret2.column3 }}</th>
                      <th>{{ maret2.column4 }}</th>
                      <th>{{ maret2.column5 }}</th>
                      <th>{{ maret2.column6 }}</th>
                      <th>{{ maret2.column7 }}</th>
                      <th>{{ maret2.column8 }}</th>
                      <th>{{ maret2.column9 }}</th>
                      <th>{{ maret2.column10 }}</th>
                      <th>{{ maret2.column11 }}</th>
                      <th>{{ maret2.column12 }}</th>
                      <th>{{ maret2.column13 }}</th>
                      <th>{{ maret2.column14 }}</th>
                      <th>{{ maret2.column15 }}</th>
                      <th>{{ maret2.column16 }}</th>
                      <th>{{ maret2.column17 }}</th>
                      <th>{{ maret2.column18 }}</th>
                      <th>{{ maret2.column19 }}</th>
                      <th>{{ maret2.column20 }}</th>
                      <th>{{ maret2.column21 }}</th>
                      <th>{{ maret2.column22 }}</th>
                      <th>{{ maret2.column23 }}</th>
                      <th>{{ maret2.column24 }}</th>
                      <th>{{ maret2.column25 }}</th>
                      <th>{{ maret2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>4</th>
                      <th>April</th>
                      <th>{{ april2.column3 }}</th>
                      <th>{{ april2.column4 }}</th>
                      <th>{{ april2.column5 }}</th>
                      <th>{{ april2.column6 }}</th>
                      <th>{{ april2.column7 }}</th>
                      <th>{{ april2.column8 }}</th>
                      <th>{{ april2.column9 }}</th>
                      <th>{{ april2.column10 }}</th>
                      <th>{{ april2.column11 }}</th>
                      <th>{{ april2.column12 }}</th>
                      <th>{{ april2.column13 }}</th>
                      <th>{{ april2.column14 }}</th>
                      <th>{{ april2.column15 }}</th>
                      <th>{{ april2.column16 }}</th>
                      <th>{{ april2.column17 }}</th>
                      <th>{{ april2.column18 }}</th>
                      <th>{{ april2.column19 }}</th>
                      <th>{{ april2.column20 }}</th>
                      <th>{{ april2.column21 }}</th>
                      <th>{{ april2.column22 }}</th>
                      <th>{{ april2.column23 }}</th>
                      <th>{{ april2.column24 }}</th>
                      <th>{{ april2.column25 }}</th>
                      <th>{{ april2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>5</th>
                      <th>Mei</th>
                      <th>{{ mei2.column3 }}</th>
                      <th>{{ mei2.column4 }}</th>
                      <th>{{ mei2.column5 }}</th>
                      <th>{{ mei2.column6 }}</th>
                      <th>{{ mei2.column7 }}</th>
                      <th>{{ mei2.column8 }}</th>
                      <th>{{ mei2.column9 }}</th>
                      <th>{{ mei2.column10 }}</th>
                      <th>{{ mei2.column11 }}</th>
                      <th>{{ mei2.column12 }}</th>
                      <th>{{ mei2.column13 }}</th>
                      <th>{{ mei2.column14 }}</th>
                      <th>{{ mei2.column15 }}</th>
                      <th>{{ mei2.column16 }}</th>
                      <th>{{ mei2.column17 }}</th>
                      <th>{{ mei2.column18 }}</th>
                      <th>{{ mei2.column19 }}</th>
                      <th>{{ mei2.column20 }}</th>
                      <th>{{ mei2.column21 }}</th>
                      <th>{{ mei2.column22 }}</th>
                      <th>{{ mei2.column23 }}</th>
                      <th>{{ mei2.column24 }}</th>
                      <th>{{ mei2.column25 }}</th>
                      <th>{{ mei2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>6</th>
                      <th>Juni</th>
                      <th>{{ juni2.column3 }}</th>
                      <th>{{ juni2.column4 }}</th>
                      <th>{{ juni2.column5 }}</th>
                      <th>{{ juni2.column6 }}</th>
                      <th>{{ juni2.column7 }}</th>
                      <th>{{ juni2.column8 }}</th>
                      <th>{{ juni2.column9 }}</th>
                      <th>{{ juni2.column10 }}</th>
                      <th>{{ juni2.column11 }}</th>
                      <th>{{ juni2.column12 }}</th>
                      <th>{{ juni2.column13 }}</th>
                      <th>{{ juni2.column14 }}</th>
                      <th>{{ juni2.column15 }}</th>
                      <th>{{ juni2.column16 }}</th>
                      <th>{{ juni2.column17 }}</th>
                      <th>{{ juni2.column18 }}</th>
                      <th>{{ juni2.column19 }}</th>
                      <th>{{ juni2.column20 }}</th>
                      <th>{{ juni2.column21 }}</th>
                      <th>{{ juni2.column22 }}</th>
                      <th>{{ juni2.column23 }}</th>
                      <th>{{ juni2.column24 }}</th>
                      <th>{{ juni2.column25 }}</th>
                      <th>{{ juni2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>7</th>
                      <th>Juli</th>
                      <th>{{ juli2.column3 }}</th>
                      <th>{{ juli2.column4 }}</th>
                      <th>{{ juli2.column5 }}</th>
                      <th>{{ juli2.column6 }}</th>
                      <th>{{ juli2.column7 }}</th>
                      <th>{{ juli2.column8 }}</th>
                      <th>{{ juli2.column9 }}</th>
                      <th>{{ juli2.column10 }}</th>
                      <th>{{ juli2.column11 }}</th>
                      <th>{{ juli2.column12 }}</th>
                      <th>{{ juli2.column13 }}</th>
                      <th>{{ juli2.column14 }}</th>
                      <th>{{ juli2.column15 }}</th>
                      <th>{{ juli2.column16 }}</th>
                      <th>{{ juli2.column17 }}</th>
                      <th>{{ juli2.column18 }}</th>
                      <th>{{ juli2.column19 }}</th>
                      <th>{{ juli2.column20 }}</th>
                      <th>{{ juli2.column21 }}</th>
                      <th>{{ juli2.column22 }}</th>
                      <th>{{ juli2.column23 }}</th>
                      <th>{{ juli2.column24 }}</th>
                      <th>{{ juli2.column25 }}</th>
                      <th>{{ juli2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>8</th>
                      <th>Agustus</th>
                      <th>{{ agustus2.column3 }}</th>
                      <th>{{ agustus2.column4 }}</th>
                      <th>{{ agustus2.column5 }}</th>
                      <th>{{ agustus2.column6 }}</th>
                      <th>{{ agustus2.column7 }}</th>
                      <th>{{ agustus2.column8 }}</th>
                      <th>{{ agustus2.column9 }}</th>
                      <th>{{ agustus2.column10 }}</th>
                      <th>{{ agustus2.column11 }}</th>
                      <th>{{ agustus2.column12 }}</th>
                      <th>{{ agustus2.column13 }}</th>
                      <th>{{ agustus2.column14 }}</th>
                      <th>{{ agustus2.column15 }}</th>
                      <th>{{ agustus2.column16 }}</th>
                      <th>{{ agustus2.column17 }}</th>
                      <th>{{ agustus2.column18 }}</th>
                      <th>{{ agustus2.column19 }}</th>
                      <th>{{ agustus2.column20 }}</th>
                      <th>{{ agustus2.column21 }}</th>
                      <th>{{ agustus2.column22 }}</th>
                      <th>{{ agustus2.column23 }}</th>
                      <th>{{ agustus2.column24 }}</th>
                      <th>{{ agustus2.column25 }}</th>
                      <th>{{ agustus2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>9</th>
                      <th>September</th>
                      <th>{{ september2.column3 }}</th>
                      <th>{{ september2.column4 }}</th>
                      <th>{{ september2.column5 }}</th>
                      <th>{{ september2.column6 }}</th>
                      <th>{{ september2.column7 }}</th>
                      <th>{{ september2.column8 }}</th>
                      <th>{{ september2.column9 }}</th>
                      <th>{{ september2.column10 }}</th>
                      <th>{{ september2.column11 }}</th>
                      <th>{{ september2.column12 }}</th>
                      <th>{{ september2.column13 }}</th>
                      <th>{{ september2.column14 }}</th>
                      <th>{{ september2.column15 }}</th>
                      <th>{{ september2.column16 }}</th>
                      <th>{{ september2.column17 }}</th>
                      <th>{{ september2.column18 }}</th>
                      <th>{{ september2.column19 }}</th>
                      <th>{{ september2.column20 }}</th>
                      <th>{{ september2.column21 }}</th>
                      <th>{{ september2.column22 }}</th>
                      <th>{{ september2.column23 }}</th>
                      <th>{{ september2.column24 }}</th>
                      <th>{{ september2.column25 }}</th>
                      <th>{{ september2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>10</th>
                      <th>Oktober</th>
                      <th>{{ oktober2.column3 }}</th>
                      <th>{{ oktober2.column4 }}</th>
                      <th>{{ oktober2.column5 }}</th>
                      <th>{{ oktober2.column6 }}</th>
                      <th>{{ oktober2.column7 }}</th>
                      <th>{{ oktober2.column8 }}</th>
                      <th>{{ oktober2.column9 }}</th>
                      <th>{{ oktober2.column10 }}</th>
                      <th>{{ oktober2.column11 }}</th>
                      <th>{{ oktober2.column12 }}</th>
                      <th>{{ oktober2.column13 }}</th>
                      <th>{{ oktober2.column14 }}</th>
                      <th>{{ oktober2.column15 }}</th>
                      <th>{{ oktober2.column16 }}</th>
                      <th>{{ oktober2.column17 }}</th>
                      <th>{{ oktober2.column18 }}</th>
                      <th>{{ oktober2.column19 }}</th>
                      <th>{{ oktober2.column20 }}</th>
                      <th>{{ oktober2.column21 }}</th>
                      <th>{{ oktober2.column22 }}</th>
                      <th>{{ oktober2.column23 }}</th>
                      <th>{{ oktober2.column24 }}</th>
                      <th>{{ oktober2.column25 }}</th>
                      <th>{{ oktober2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>11</th>
                      <th>November</th>
                      <th>{{ november2.column3 }}</th>
                      <th>{{ november2.column4 }}</th>
                      <th>{{ november2.column5 }}</th>
                      <th>{{ november2.column6 }}</th>
                      <th>{{ november2.column7 }}</th>
                      <th>{{ november2.column8 }}</th>
                      <th>{{ november2.column9 }}</th>
                      <th>{{ november2.column10 }}</th>
                      <th>{{ november2.column11 }}</th>
                      <th>{{ november2.column12 }}</th>
                      <th>{{ november2.column13 }}</th>
                      <th>{{ november2.column14 }}</th>
                      <th>{{ november2.column15 }}</th>
                      <th>{{ november2.column16 }}</th>
                      <th>{{ november2.column17 }}</th>
                      <th>{{ november2.column18 }}</th>
                      <th>{{ november2.column19 }}</th>
                      <th>{{ november2.column20 }}</th>
                      <th>{{ november2.column21 }}</th>
                      <th>{{ november2.column22 }}</th>
                      <th>{{ november2.column23 }}</th>
                      <th>{{ november2.column24 }}</th>
                      <th>{{ november2.column25 }}</th>
                      <th>{{ november2.column26 }}</th>
                    </tr>
                    <tr>
                      <th>12</th>
                      <th>Desember</th>
                      <th>{{ desember2.column3 }}</th>
                      <th>{{ desember2.column4 }}</th>
                      <th>{{ desember2.column5 }}</th>
                      <th>{{ desember2.column6 }}</th>
                      <th>{{ desember2.column7 }}</th>
                      <th>{{ desember2.column8 }}</th>
                      <th>{{ desember2.column9 }}</th>
                      <th>{{ desember2.column10 }}</th>
                      <th>{{ desember2.column11 }}</th>
                      <th>{{ desember2.column12 }}</th>
                      <th>{{ desember2.column13 }}</th>
                      <th>{{ desember2.column14 }}</th>
                      <th>{{ desember2.column15 }}</th>
                      <th>{{ desember2.column16 }}</th>
                      <th>{{ desember2.column17 }}</th>
                      <th>{{ desember2.column18 }}</th>
                      <th>{{ desember2.column19 }}</th>
                      <th>{{ desember2.column20 }}</th>
                      <th>{{ desember2.column21 }}</th>
                      <th>{{ desember2.column22 }}</th>
                      <th>{{ desember2.column23 }}</th>
                      <th>{{ desember2.column24 }}</th>
                      <th>{{ desember2.column25 }}</th>
                      <th>{{ desember2.column26 }}</th>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row justify-content-start">
                <table
                  class="table table-bordered text-center"
                  style="font-size: 10px"
                  :class="{'table-responsive': printOut}"
                >
                  <tr>
                    <th rowspan="4" style="vertical-align: middle">NO</th>
                    <th rowspan="4" style="vertical-align: middle">BULAN</th>
                    <th colspan="2" rowspan="2" style="vertical-align: middle">
                      IMUNISASI TT IBU HAMIL
                    </th>
                    <th colspan="24" style="vertical-align: middle">
                      JUMLAH BAYI YANG DIIMUNISASI
                    </th>
                    <th colspan="4">BALITA YANG MENDERITA DIARE</th>
                    <th rowspan="4" style="vertical-align: middle">
                      KETERANGAN
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2" rowspan="2" style="vertical-align: middle">
                      BCG
                    </th>
                    <th colspan="6">DPT</th>
                    <th colspan="8">POLIO</th>
                    <th colspan="2" rowspan="2" style="vertical-align: middle">
                      CAMPAK
                    </th>
                    <th colspan="6">HEPATITIS B</th>
                    <th colspan="2" rowspan="2" style="vertical-align: middle">
                      JUMLAH
                    </th>
                    <th colspan="2" rowspan="2">YANG MENDAPAT ORALIT</th>
                  </tr>
                  <tr>
                    <th rowspan="2" style="vertical-align: middle">I</th>
                    <th rowspan="2" style="vertical-align: middle">II</th>
                    <th colspan="2">I</th>
                    <th colspan="2">II</th>
                    <th colspan="2">III</th>
                    <th colspan="2">I</th>
                    <th colspan="2">II</th>
                    <th colspan="2">III</th>
                    <th colspan="2">IV</th>
                    <th colspan="2">I</th>
                    <th colspan="2">II</th>
                    <th colspan="2">III</th>
                  </tr>
                  <tr>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                    <th>L</th>
                    <th>P</th>
                  </tr>
                  <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>19</th>
                    <th>20</th>
                    <th>21</th>
                    <th>22</th>
                    <th>23</th>
                    <th>24</th>
                    <th>25</th>
                    <th>26</th>
                    <th>27</th>
                    <th>28</th>
                    <th>29</th>
                    <th>30</th>
                    <th>31</th>
                    <th>32</th>
                    <th>33</th>
                  </tr>
                  <tr>
                    <th>1</th>
                    <th>Januari</th>
                    <th>{{ januari3.column3 }}</th>
                    <th>{{ januari3.column4 }}</th>
                    <th>{{ januari3.column5 }}</th>
                    <th>{{ januari3.column6 }}</th>
                    <th>{{ januari3.column7 }}</th>
                    <th>{{ januari3.column8 }}</th>
                    <th>{{ januari3.column9 }}</th>
                    <th>{{ januari3.column10 }}</th>
                    <th>{{ januari3.column11 }}</th>
                    <th>{{ januari3.column12 }}</th>
                    <th>{{ januari3.column13 }}</th>
                    <th>{{ januari3.column14 }}</th>
                    <th>{{ januari3.column15 }}</th>
                    <th>{{ januari3.column16 }}</th>
                    <th>{{ januari3.column17 }}</th>
                    <th>{{ januari3.column18 }}</th>
                    <th>{{ januari3.column19 }}</th>
                    <th>{{ januari3.column20 }}</th>
                    <th>{{ januari3.column21 }}</th>
                    <th>{{ januari3.column22 }}</th>
                    <th>{{ januari3.column23 }}</th>
                    <th>{{ januari3.column24 }}</th>
                    <th>{{ januari3.column25 }}</th>
                    <th>{{ januari3.column26 }}</th>
                    <th>{{ januari3.column27 }}</th>
                    <th>{{ januari3.column28 }}</th>
                    <th>{{ januari3.column29 }}</th>
                    <th>{{ januari3.column30 }}</th>
                    <th>{{ januari3.column31 }}</th>
                    <th>{{ januari3.column32 }}</th>
                    <th>{{ januari3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>2</th>
                    <th>Februari</th>
                    <th>{{ februari3.column3 }}</th>
                    <th>{{ februari3.column4 }}</th>
                    <th>{{ februari3.column5 }}</th>
                    <th>{{ februari3.column6 }}</th>
                    <th>{{ februari3.column7 }}</th>
                    <th>{{ februari3.column8 }}</th>
                    <th>{{ februari3.column9 }}</th>
                    <th>{{ februari3.column10 }}</th>
                    <th>{{ februari3.column11 }}</th>
                    <th>{{ februari3.column12 }}</th>
                    <th>{{ februari3.column13 }}</th>
                    <th>{{ februari3.column14 }}</th>
                    <th>{{ februari3.column15 }}</th>
                    <th>{{ februari3.column16 }}</th>
                    <th>{{ februari3.column17 }}</th>
                    <th>{{ februari3.column18 }}</th>
                    <th>{{ februari3.column19 }}</th>
                    <th>{{ februari3.column20 }}</th>
                    <th>{{ februari3.column21 }}</th>
                    <th>{{ februari3.column22 }}</th>
                    <th>{{ februari3.column23 }}</th>
                    <th>{{ februari3.column24 }}</th>
                    <th>{{ februari3.column25 }}</th>
                    <th>{{ februari3.column26 }}</th>
                    <th>{{ februari3.column27 }}</th>
                    <th>{{ februari3.column28 }}</th>
                    <th>{{ februari3.column29 }}</th>
                    <th>{{ februari3.column30 }}</th>
                    <th>{{ februari3.column31 }}</th>
                    <th>{{ februari3.column32 }}</th>
                    <th>{{ februari3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>3</th>
                    <th>Maret</th>
                    <th>{{ maret3.column3 }}</th>
                    <th>{{ maret3.column4 }}</th>
                    <th>{{ maret3.column5 }}</th>
                    <th>{{ maret3.column6 }}</th>
                    <th>{{ maret3.column7 }}</th>
                    <th>{{ maret3.column8 }}</th>
                    <th>{{ maret3.column9 }}</th>
                    <th>{{ maret3.column10 }}</th>
                    <th>{{ maret3.column11 }}</th>
                    <th>{{ maret3.column12 }}</th>
                    <th>{{ maret3.column13 }}</th>
                    <th>{{ maret3.column14 }}</th>
                    <th>{{ maret3.column15 }}</th>
                    <th>{{ maret3.column16 }}</th>
                    <th>{{ maret3.column17 }}</th>
                    <th>{{ maret3.column18 }}</th>
                    <th>{{ maret3.column19 }}</th>
                    <th>{{ maret3.column20 }}</th>
                    <th>{{ maret3.column21 }}</th>
                    <th>{{ maret3.column22 }}</th>
                    <th>{{ maret3.column23 }}</th>
                    <th>{{ maret3.column24 }}</th>
                    <th>{{ maret3.column25 }}</th>
                    <th>{{ maret3.column26 }}</th>
                    <th>{{ maret3.column27 }}</th>
                    <th>{{ maret3.column28 }}</th>
                    <th>{{ maret3.column29 }}</th>
                    <th>{{ maret3.column30 }}</th>
                    <th>{{ maret3.column31 }}</th>
                    <th>{{ maret3.column32 }}</th>
                    <th>{{ maret3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>4</th>
                    <th>April</th>
                    <th>{{ april3.column3 }}</th>
                    <th>{{ april3.column4 }}</th>
                    <th>{{ april3.column5 }}</th>
                    <th>{{ april3.column6 }}</th>
                    <th>{{ april3.column7 }}</th>
                    <th>{{ april3.column8 }}</th>
                    <th>{{ april3.column9 }}</th>
                    <th>{{ april3.column10 }}</th>
                    <th>{{ april3.column11 }}</th>
                    <th>{{ april3.column12 }}</th>
                    <th>{{ april3.column13 }}</th>
                    <th>{{ april3.column14 }}</th>
                    <th>{{ april3.column15 }}</th>
                    <th>{{ april3.column16 }}</th>
                    <th>{{ april3.column17 }}</th>
                    <th>{{ april3.column18 }}</th>
                    <th>{{ april3.column19 }}</th>
                    <th>{{ april3.column20 }}</th>
                    <th>{{ april3.column21 }}</th>
                    <th>{{ april3.column22 }}</th>
                    <th>{{ april3.column23 }}</th>
                    <th>{{ april3.column24 }}</th>
                    <th>{{ april3.column25 }}</th>
                    <th>{{ april3.column26 }}</th>
                    <th>{{ april3.column27 }}</th>
                    <th>{{ april3.column28 }}</th>
                    <th>{{ april3.column29 }}</th>
                    <th>{{ april3.column30 }}</th>
                    <th>{{ april3.column31 }}</th>
                    <th>{{ april3.column32 }}</th>
                    <th>{{ april3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>5</th>
                    <th>Mei</th>
                    <th>{{ mei3.column3 }}</th>
                    <th>{{ mei3.column4 }}</th>
                    <th>{{ mei3.column5 }}</th>
                    <th>{{ mei3.column6 }}</th>
                    <th>{{ mei3.column7 }}</th>
                    <th>{{ mei3.column8 }}</th>
                    <th>{{ mei3.column9 }}</th>
                    <th>{{ mei3.column10 }}</th>
                    <th>{{ mei3.column11 }}</th>
                    <th>{{ mei3.column12 }}</th>
                    <th>{{ mei3.column13 }}</th>
                    <th>{{ mei3.column14 }}</th>
                    <th>{{ mei3.column15 }}</th>
                    <th>{{ mei3.column16 }}</th>
                    <th>{{ mei3.column17 }}</th>
                    <th>{{ mei3.column18 }}</th>
                    <th>{{ mei3.column19 }}</th>
                    <th>{{ mei3.column20 }}</th>
                    <th>{{ mei3.column21 }}</th>
                    <th>{{ mei3.column22 }}</th>
                    <th>{{ mei3.column23 }}</th>
                    <th>{{ mei3.column24 }}</th>
                    <th>{{ mei3.column25 }}</th>
                    <th>{{ mei3.column26 }}</th>
                    <th>{{ mei3.column27 }}</th>
                    <th>{{ mei3.column28 }}</th>
                    <th>{{ mei3.column29 }}</th>
                    <th>{{ mei3.column30 }}</th>
                    <th>{{ mei3.column31 }}</th>
                    <th>{{ mei3.column32 }}</th>
                    <th>{{ mei3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>6</th>
                    <th>Juni</th>
                    <th>{{ juni3.column3 }}</th>
                    <th>{{ juni3.column4 }}</th>
                    <th>{{ juni3.column5 }}</th>
                    <th>{{ juni3.column6 }}</th>
                    <th>{{ juni3.column7 }}</th>
                    <th>{{ juni3.column8 }}</th>
                    <th>{{ juni3.column9 }}</th>
                    <th>{{ juni3.column10 }}</th>
                    <th>{{ juni3.column11 }}</th>
                    <th>{{ juni3.column12 }}</th>
                    <th>{{ juni3.column13 }}</th>
                    <th>{{ juni3.column14 }}</th>
                    <th>{{ juni3.column15 }}</th>
                    <th>{{ juni3.column16 }}</th>
                    <th>{{ juni3.column17 }}</th>
                    <th>{{ juni3.column18 }}</th>
                    <th>{{ juni3.column19 }}</th>
                    <th>{{ juni3.column20 }}</th>
                    <th>{{ juni3.column21 }}</th>
                    <th>{{ juni3.column22 }}</th>
                    <th>{{ juni3.column23 }}</th>
                    <th>{{ juni3.column24 }}</th>
                    <th>{{ juni3.column25 }}</th>
                    <th>{{ juni3.column26 }}</th>
                    <th>{{ juni3.column27 }}</th>
                    <th>{{ juni3.column28 }}</th>
                    <th>{{ juni3.column29 }}</th>
                    <th>{{ juni3.column30 }}</th>
                    <th>{{ juni3.column31 }}</th>
                    <th>{{ juni3.column32 }}</th>
                    <th>{{ juni3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>7</th>
                    <th>Juli</th>
                    <th>{{ juli3.column3 }}</th>
                    <th>{{ juli3.column4 }}</th>
                    <th>{{ juli3.column5 }}</th>
                    <th>{{ juli3.column6 }}</th>
                    <th>{{ juli3.column7 }}</th>
                    <th>{{ juli3.column8 }}</th>
                    <th>{{ juli3.column9 }}</th>
                    <th>{{ juli3.column10 }}</th>
                    <th>{{ juli3.column11 }}</th>
                    <th>{{ juli3.column12 }}</th>
                    <th>{{ juli3.column13 }}</th>
                    <th>{{ juli3.column14 }}</th>
                    <th>{{ juli3.column15 }}</th>
                    <th>{{ juli3.column16 }}</th>
                    <th>{{ juli3.column17 }}</th>
                    <th>{{ juli3.column18 }}</th>
                    <th>{{ juli3.column19 }}</th>
                    <th>{{ juli3.column20 }}</th>
                    <th>{{ juli3.column21 }}</th>
                    <th>{{ juli3.column22 }}</th>
                    <th>{{ juli3.column23 }}</th>
                    <th>{{ juli3.column24 }}</th>
                    <th>{{ juli3.column25 }}</th>
                    <th>{{ juli3.column26 }}</th>
                    <th>{{ juli3.column27 }}</th>
                    <th>{{ juli3.column28 }}</th>
                    <th>{{ juli3.column29 }}</th>
                    <th>{{ juli3.column30 }}</th>
                    <th>{{ juli3.column31 }}</th>
                    <th>{{ juli3.column32 }}</th>
                    <th>{{ juli3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>8</th>
                    <th>Agustus</th>
                    <th>{{ agustus3.column3 }}</th>
                    <th>{{ agustus3.column4 }}</th>
                    <th>{{ agustus3.column5 }}</th>
                    <th>{{ agustus3.column6 }}</th>
                    <th>{{ agustus3.column7 }}</th>
                    <th>{{ agustus3.column8 }}</th>
                    <th>{{ agustus3.column9 }}</th>
                    <th>{{ agustus3.column10 }}</th>
                    <th>{{ agustus3.column11 }}</th>
                    <th>{{ agustus3.column12 }}</th>
                    <th>{{ agustus3.column13 }}</th>
                    <th>{{ agustus3.column14 }}</th>
                    <th>{{ agustus3.column15 }}</th>
                    <th>{{ agustus3.column16 }}</th>
                    <th>{{ agustus3.column17 }}</th>
                    <th>{{ agustus3.column18 }}</th>
                    <th>{{ agustus3.column19 }}</th>
                    <th>{{ agustus3.column20 }}</th>
                    <th>{{ agustus3.column21 }}</th>
                    <th>{{ agustus3.column22 }}</th>
                    <th>{{ agustus3.column23 }}</th>
                    <th>{{ agustus3.column24 }}</th>
                    <th>{{ agustus3.column25 }}</th>
                    <th>{{ agustus3.column27 }}</th>
                    <th>{{ agustus3.column26 }}</th>
                    <th>{{ agustus3.column28 }}</th>
                    <th>{{ agustus3.column29 }}</th>
                    <th>{{ agustus3.column30 }}</th>
                    <th>{{ agustus3.column31 }}</th>
                    <th>{{ agustus3.column32 }}</th>
                    <th>{{ agustus3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>9</th>
                    <th>September</th>
                    <th>{{ september3.column3 }}</th>
                    <th>{{ september3.column4 }}</th>
                    <th>{{ september3.column5 }}</th>
                    <th>{{ september3.column6 }}</th>
                    <th>{{ september3.column7 }}</th>
                    <th>{{ september3.column8 }}</th>
                    <th>{{ september3.column9 }}</th>
                    <th>{{ september3.column10 }}</th>
                    <th>{{ september3.column11 }}</th>
                    <th>{{ september3.column12 }}</th>
                    <th>{{ september3.column13 }}</th>
                    <th>{{ september3.column14 }}</th>
                    <th>{{ september3.column15 }}</th>
                    <th>{{ september3.column16 }}</th>
                    <th>{{ september3.column17 }}</th>
                    <th>{{ september3.column18 }}</th>
                    <th>{{ september3.column19 }}</th>
                    <th>{{ september3.column20 }}</th>
                    <th>{{ september3.column21 }}</th>
                    <th>{{ september3.column22 }}</th>
                    <th>{{ september3.column23 }}</th>
                    <th>{{ september3.column24 }}</th>
                    <th>{{ september3.column25 }}</th>
                    <th>{{ september3.column26 }}</th>
                    <th>{{ september3.column27 }}</th>
                    <th>{{ september3.column28 }}</th>
                    <th>{{ september3.column29 }}</th>
                    <th>{{ september3.column30 }}</th>
                    <th>{{ september3.column31 }}</th>
                    <th>{{ september3.column32 }}</th>
                    <th>{{ september3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>10</th>
                    <th>Oktober</th>
                    <th>{{ oktober3.column3 }}</th>
                    <th>{{ oktober3.column4 }}</th>
                    <th>{{ oktober3.column5 }}</th>
                    <th>{{ oktober3.column6 }}</th>
                    <th>{{ oktober3.column7 }}</th>
                    <th>{{ oktober3.column8 }}</th>
                    <th>{{ oktober3.column9 }}</th>
                    <th>{{ oktober3.column10 }}</th>
                    <th>{{ oktober3.column11 }}</th>
                    <th>{{ oktober3.column12 }}</th>
                    <th>{{ oktober3.column13 }}</th>
                    <th>{{ oktober3.column14 }}</th>
                    <th>{{ oktober3.column15 }}</th>
                    <th>{{ oktober3.column16 }}</th>
                    <th>{{ oktober3.column17 }}</th>
                    <th>{{ oktober3.column18 }}</th>
                    <th>{{ oktober3.column19 }}</th>
                    <th>{{ oktober3.column20 }}</th>
                    <th>{{ oktober3.column21 }}</th>
                    <th>{{ oktober3.column22 }}</th>
                    <th>{{ oktober3.column23 }}</th>
                    <th>{{ oktober3.column24 }}</th>
                    <th>{{ oktober3.column25 }}</th>
                    <th>{{ oktober3.column26 }}</th>
                    <th>{{ oktober3.column27 }}</th>
                    <th>{{ oktober3.column28 }}</th>
                    <th>{{ oktober3.column29 }}</th>
                    <th>{{ oktober3.column30 }}</th>
                    <th>{{ oktober3.column31 }}</th>
                    <th>{{ oktober3.column32 }}</th>
                    <th>{{ oktober3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>11</th>
                    <th>November</th>
                    <th>{{ november3.column3 }}</th>
                    <th>{{ november3.column4 }}</th>
                    <th>{{ november3.column5 }}</th>
                    <th>{{ november3.column6 }}</th>
                    <th>{{ november3.column7 }}</th>
                    <th>{{ november3.column8 }}</th>
                    <th>{{ november3.column9 }}</th>
                    <th>{{ november3.column10 }}</th>
                    <th>{{ november3.column11 }}</th>
                    <th>{{ november3.column12 }}</th>
                    <th>{{ november3.column13 }}</th>
                    <th>{{ november3.column14 }}</th>
                    <th>{{ november3.column15 }}</th>
                    <th>{{ november3.column16 }}</th>
                    <th>{{ november3.column17 }}</th>
                    <th>{{ november3.column18 }}</th>
                    <th>{{ november3.column19 }}</th>
                    <th>{{ november3.column20 }}</th>
                    <th>{{ november3.column21 }}</th>
                    <th>{{ november3.column22 }}</th>
                    <th>{{ november3.column23 }}</th>
                    <th>{{ november3.column24 }}</th>
                    <th>{{ november3.column25 }}</th>
                    <th>{{ november3.column26 }}</th>
                    <th>{{ november3.column27 }}</th>
                    <th>{{ november3.column28 }}</th>
                    <th>{{ november3.column29 }}</th>
                    <th>{{ november3.column30 }}</th>
                    <th>{{ november3.column31 }}</th>
                    <th>{{ november3.column32 }}</th>
                    <th>{{ november3.column33 }}</th>
                  </tr>
                  <tr>
                    <th>12</th>
                    <th>Desember</th>
                    <th>{{ desember3.column3 }}</th>
                    <th>{{ desember3.column4 }}</th>
                    <th>{{ desember3.column5 }}</th>
                    <th>{{ desember3.column6 }}</th>
                    <th>{{ desember3.column7 }}</th>
                    <th>{{ desember3.column8 }}</th>
                    <th>{{ desember3.column9 }}</th>
                    <th>{{ desember3.column10 }}</th>
                    <th>{{ desember3.column11 }}</th>
                    <th>{{ desember3.column12 }}</th>
                    <th>{{ desember3.column13 }}</th>
                    <th>{{ desember3.column14 }}</th>
                    <th>{{ desember3.column15 }}</th>
                    <th>{{ desember3.column16 }}</th>
                    <th>{{ desember3.column17 }}</th>
                    <th>{{ desember3.column18 }}</th>
                    <th>{{ desember3.column19 }}</th>
                    <th>{{ desember3.column20 }}</th>
                    <th>{{ desember3.column21 }}</th>
                    <th>{{ desember3.column22 }}</th>
                    <th>{{ desember3.column23 }}</th>
                    <th>{{ desember3.column24 }}</th>
                    <th>{{ desember3.column25 }}</th>
                    <th>{{ desember3.column26 }}</th>
                    <th>{{ desember3.column27 }}</th>
                    <th>{{ desember3.column28 }}</th>
                    <th>{{ desember3.column29 }}</th>
                    <th>{{ desember3.column30 }}</th>
                    <th>{{ desember3.column31 }}</th>
                    <th>{{ desember3.column32 }}</th>
                    <th>{{ desember3.column33 }}</th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End::Laporan -->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import api from "@/core/services/api.service.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  data() {
    return {
      activePicker: null,
      date: null,
      menu: false,
      year: null,
      //
      buttonPrint: false,
      printOut: true,
      modal: false,
      currentTime: "",
      chooseYear: false,
      dataLoaded: false,
      currentUser: getUser(),
      filter: {
        year: null,
        posyandu_id:
          getUser().role_key_name == "super_admin" ||
          getUser().user_type_id == 3
            ? ""
            : getUser().posyandu_id,
      },
      posyandu_list: [],
      posyandu: {},
      /*
        TABLE 1
      */
      januari1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      februari1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      maret1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      april1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      mei1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      juni1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      juli1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      agustus1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      september1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      oktober1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      november1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      desember1: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
      },
      /*
        TABLE 2
      */
      januari2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      februari2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      maret2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      april2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      mei2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      juni2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      juli2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      agustus2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      september2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      oktober2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      november2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      desember2: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
      },
      /*
        TABLE 3
      */
      januari3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      februari3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      maret3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      april3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      mei3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      juni3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      juli3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      agustus3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      september3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      oktober3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      november3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
      desember3: {
        column3: "-",
        column4: "-",
        column5: "-",
        column6: "-",
        column7: "-",
        column8: "-",
        column9: "-",
        column10: "-",
        column11: "-",
        column12: "-",
        column13: "-",
        column14: "-",
        column15: "-",
        column16: "-",
        column17: "-",
        column18: "-",
        column19: "-",
        column20: "-",
        column21: "-",
        column22: "-",
        column23: "-",
        column24: "-",
        column25: "-",
        column26: "-",
        column27: "-",
        column28: "-",
        column29: "-",
        column30: "-",
        column31: "-",
        column32: "-",
        column33: "-",
      },
    };
  },
  methods: {
    saveYear(year) {
      console.log("refs", this.$refs);
      this.filter.year = this.$refs.picker.inputYear;
      this.$refs.dialog.save(this.filter.year);
      console.log("year", this.filter.year);
      console.log("filter", this.filter);
      this.modal = false;
      this.$refs.picker.activePicker = "YEAR";

      if (
        getUser().role_key_name != "super_admin" &&
        getUser().user_type_id != 3
      ) {
        /* 
        TABLE 1
      */
        this.getAllReportTable1();
        /* 
        TABLE 2
      */
        this.getReportTable2();
        this.getAcceptorTable2();
        /* 
        TABLE 3
      */
        this.getReportTable3();
        this.getPosyanduById();
        this.dataLoaded = true
        this.buttonPrint = true
      } else {
        this.chooseYear = true
      }
    },
    filterByMonth(evt) {
      this.chooseYear = true;
    },
    setCurrentTime() {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let localdata = new Date().toLocaleDateString("id-ID", options);
      return localdata;
    },
    btnPrintOnClick() {
      this.printOut = false
      setTimeout(() => {
        this.$htmlToPaper("printMe");
      }, 1000);
      setTimeout(() => {
        this.printOut = true
      }, 2000);
    },
    async getAllReportTable1Male(month) {
      let year = new Date().toISOString().substr(0, 4);
      let response = await module.getReport(
        "api/all-report/table1/" + this.filter.posyandu_id,
        `?month=${this.filter.year}-${month}&gender=male`
      );
      if (month == "01") {
        this.januari1.column3 = response.total012_new;
        this.januari1.column5 = response.total012_old;
        this.januari1.column7 = response.total_15_new;
        this.januari1.column9 = response.total_15_old;
        this.januari1.column11 = response.total_wus;
        this.januari1.column12 = response.total_pus;
        this.januari1.column13 = response.total_pregnant;
        this.januari1.column14 = response.total_breestfeeding;
        this.januari1.column15 = response.total_cadre;
        this.januari1.column21 = response.total_toddler_safe;
        this.januari1.column23 = response.total_toddler_die;
      } else if (month == "02") {
        this.februari1.column3 = response.total012_new;
        this.februari1.column5 = response.total012_old;
        this.februari1.column7 = response.total_15_new;
        this.februari1.column9 = response.total_15_old;
        this.februari1.column11 = response.total_wus;
        this.februari1.column12 = response.total_pus;
        this.februari1.column13 = response.total_pregnant;
        this.februari1.column14 = response.total_breestfeeding;
        this.februari1.column15 = response.total_cadre;
        this.februari1.column21 = response.total_toddler_safe;
        this.februari1.column23 = response.total_toddler_die;
      } else if (month == "03") {
        this.maret1.column3 = response.total012_new;
        this.maret1.column5 = response.total012_old;
        this.maret1.column7 = response.total_15_new;
        this.maret1.column9 = response.total_15_old;
        this.maret1.column11 = response.total_wus;
        this.maret1.column12 = response.total_pus;
        this.maret1.column13 = response.total_pregnant;
        this.maret1.column14 = response.total_breestfeeding;
        this.maret1.column15 = response.total_cadre;
        this.maret1.column21 = response.total_toddler_safe;
        this.maret1.column23 = response.total_toddler_die;
      } else if (month == "04") {
        this.april1.column3 = response.total012_new;
        this.april1.column5 = response.total012_old;
        this.april1.column7 = response.total_15_new;
        this.april1.column9 = response.total_15_old;
        this.april1.column11 = response.total_wus;
        this.april1.column12 = response.total_pus;
        this.april1.column13 = response.total_pregnant;
        this.april1.column14 = response.total_breestfeeding;
        this.april1.column15 = response.total_cadre;
        this.april1.column21 = response.total_toddler_safe;
        this.april1.column23 = response.total_toddler_die;
      } else if (month == "05") {
        this.mei1.column3 = response.total012_new;
        this.mei1.column5 = response.total012_old;
        this.mei1.column7 = response.total_15_new;
        this.mei1.column9 = response.total_15_old;
        this.mei1.column11 = response.total_wus;
        this.mei1.column12 = response.total_pus;
        this.mei1.column13 = response.total_pregnant;
        this.mei1.column14 = response.total_breestfeeding;
        this.mei1.column15 = response.total_cadre;
        this.mei1.column21 = response.total_toddler_safe;
        this.mei1.column23 = response.total_toddler_die;
      } else if (month == "06") {
        this.juni1.column3 = response.total012_new;
        this.juni1.column5 = response.total012_old;
        this.juni1.column7 = response.total_15_new;
        this.juni1.column9 = response.total_15_old;
        this.juni1.column11 = response.total_wus;
        this.juni1.column12 = response.total_pus;
        this.juni1.column13 = response.total_pregnant;
        this.juni1.column14 = response.total_breestfeeding;
        this.juni1.column15 = response.total_cadre;
        this.juni1.column21 = response.total_toddler_safe;
        this.juni1.column23 = response.total_toddler_die;
      } else if (month == "07") {
        this.juli1.column3 = response.total012_new;
        this.juli1.column5 = response.total012_old;
        this.juli1.column7 = response.total_15_new;
        this.juli1.column9 = response.total_15_old;
        this.juli1.column11 = response.total_wus;
        this.juli1.column12 = response.total_pus;
        this.juli1.column13 = response.total_pregnant;
        this.juli1.column14 = response.total_breestfeeding;
        this.juli1.column15 = response.total_cadre;
        this.juli1.column21 = response.total_toddler_safe;
        this.juli1.column23 = response.total_toddler_die;
      } else if (month == "08") {
        this.agustus1.column3 = response.total012_new;
        this.agustus1.column5 = response.total012_old;
        this.agustus1.column7 = response.total_15_new;
        this.agustus1.column9 = response.total_15_old;
        this.agustus1.column11 = response.total_wus;
        this.agustus1.column12 = response.total_pus;
        this.agustus1.column13 = response.total_pregnant;
        this.agustus1.column14 = response.total_breestfeeding;
        this.agustus1.column15 = response.total_cadre;
        this.agustus1.column21 = response.total_toddler_safe;
        this.agustus1.column23 = response.total_toddler_die;
      } else if (month == "09") {
        this.september1.column3 = response.total012_new;
        this.september1.column5 = response.total012_old;
        this.september1.column7 = response.total_15_new;
        this.september1.column9 = response.total_15_old;
        this.september1.column11 = response.total_wus;
        this.september1.column12 = response.total_pus;
        this.september1.column13 = response.total_pregnant;
        this.september1.column14 = response.total_breestfeeding;
        this.september1.column15 = response.total_cadre;
        this.september1.column21 = response.total_toddler_safe;
        this.september1.column23 = response.total_toddler_die;
      } else if (month == "10") {
        this.oktober1.column3 = response.total012_new;
        this.oktober1.column5 = response.total012_old;
        this.oktober1.column7 = response.total_15_new;
        this.oktober1.column9 = response.total_15_old;
        this.oktober1.column11 = response.total_wus;
        this.oktober1.column12 = response.total_pus;
        this.oktober1.column13 = response.total_pregnant;
        this.oktober1.column14 = response.total_breestfeeding;
        this.oktober1.column15 = response.total_cadre;
        this.oktober1.column21 = response.total_toddler_safe;
        this.oktober1.column23 = response.total_toddler_die;
      } else if (month == "11") {
        this.november1.column3 = response.total012_new;
        this.november1.column5 = response.total012_old;
        this.november1.column7 = response.total_15_new;
        this.november1.column9 = response.total_15_old;
        this.november1.column11 = response.total_wus;
        this.november1.column12 = response.total_pus;
        this.november1.column13 = response.total_pregnant;
        this.november1.column14 = response.total_breestfeeding;
        this.november1.column15 = response.total_cadre;
        this.november1.column21 = response.total_toddler_safe;
        this.november1.column23 = response.total_toddler_die;
      } else if (month == "12") {
        this.desember1.column3 = response.total012_new;
        this.desember1.column5 = response.total012_old;
        this.desember1.column7 = response.total_15_new;
        this.desember1.column9 = response.total_15_old;
        this.desember1.column11 = response.total_wus;
        this.desember1.column12 = response.total_pus;
        this.desember1.column13 = response.total_pregnant;
        this.desember1.column14 = response.total_breestfeeding;
        this.desember1.column15 = response.total_cadre;
        this.desember1.column21 = response.total_toddler_safe;
        this.desember1.column23 = response.total_toddler_die;
      }
    },
    async getAllReportTable1Female(month) {
      let year = new Date().toISOString().substr(0, 4);
      let response = await module.getReport(
        "api/all-report/table1/" + this.filter.posyandu_id,
        `?month=${this.filter.year}-${month}&gender=female`
      );
      if (month == "01") {
        this.januari1.column4 = response.total012_new;
        this.januari1.column6 = response.total012_old;
        this.januari1.column8 = response.total_15_new;
        this.januari1.column10 = response.total_15_old;
        this.januari1.column16 = response.total_cadre;
        this.januari1.column22 = response.total_toddler_safe;
        this.januari1.column24 = response.total_toddler_die;
      } else if (month == "02") {
        this.februari1.column4 = response.total012_new;
        this.februari1.column6 = response.total012_old;
        this.februari1.column8 = response.total_15_new;
        this.februari1.column10 = response.total_15_old;
        this.februari1.column16 = response.total_cadre;
        this.februari1.column22 = response.total_toddler_safe;
        this.februari1.column24 = response.total_toddler_die;
      } else if (month == "03") {
        this.maret1.column4 = response.total012_new;
        this.maret1.column6 = response.total012_old;
        this.maret1.column8 = response.total_15_new;
        this.maret1.column10 = response.total_15_old;
        this.maret1.column16 = response.total_cadre;
        this.maret1.column22 = response.total_toddler_safe;
        this.maret1.column24 = response.total_toddler_die;
      } else if (month == "04") {
        this.april1.column4 = response.total012_new;
        this.april1.column6 = response.total012_old;
        this.april1.column8 = response.total_15_new;
        this.april1.column10 = response.total_15_old;
        this.april1.column16 = response.total_cadre;
        this.april1.column22 = response.total_toddler_safe;
        this.april1.column24 = response.total_toddler_die;
      } else if (month == "05") {
        this.mei1.column4 = response.total012_new;
        this.mei1.column6 = response.total012_old;
        this.mei1.column8 = response.total_15_new;
        this.mei1.column10 = response.total_15_old;
        this.mei1.column16 = response.total_cadre;
        this.mei1.column22 = response.total_toddler_safe;
        this.mei1.column24 = response.total_toddler_die;
      } else if (month == "06") {
        this.juni1.column4 = response.total012_new;
        this.juni1.column6 = response.total012_old;
        this.juni1.column8 = response.total_15_new;
        this.juni1.column10 = response.total_15_old;
        this.juni1.column16 = response.total_cadre;
        this.juni1.column22 = response.total_toddler_safe;
        this.juni1.column24 = response.total_toddler_die;
      } else if (month == "07") {
        this.juli1.column4 = response.total012_new;
        this.juli1.column6 = response.total012_old;
        this.juli1.column8 = response.total_15_new;
        this.juli1.column10 = response.total_15_old;
        this.juli1.column16 = response.total_cadre;
        this.juli1.column22 = response.total_toddler_safe;
        this.juli1.column24 = response.total_toddler_die;
      } else if (month == "08") {
        this.agustus1.column4 = response.total012_new;
        this.agustus1.column6 = response.total012_old;
        this.agustus1.column8 = response.total_15_new;
        this.agustus1.column10 = response.total_15_old;
        this.agustus1.column16 = response.total_cadre;
        this.agustus1.column22 = response.total_toddler_safe;
        this.agustus1.column24 = response.total_toddler_die;
      } else if (month == "09") {
        this.september1.column4 = response.total012_new;
        this.september1.column6 = response.total012_old;
        this.september1.column8 = response.total_15_new;
        this.september1.column10 = response.total_15_old;
        this.september1.column16 = response.total_cadre;
        this.september1.column22 = response.total_toddler_safe;
        this.september1.column24 = response.total_toddler_die;
      } else if (month == "10") {
        this.oktober1.column4 = response.total012_new;
        this.oktober1.column6 = response.total012_old;
        this.oktober1.column8 = response.total_15_new;
        this.oktober1.column10 = response.total_15_old;
        this.oktober1.column16 = response.total_cadre;
        this.oktober1.column22 = response.total_toddler_safe;
        this.oktober1.column24 = response.total_toddler_die;
      } else if (month == "11") {
        this.november1.column4 = response.total012_new;
        this.november1.column6 = response.total012_old;
        this.november1.column8 = response.total_15_new;
        this.november1.column10 = response.total_15_old;
        this.november1.column16 = response.total_cadre;
        this.november1.column22 = response.total_toddler_safe;
        this.november1.column24 = response.total_toddler_die;
      } else if (month == "12") {
        this.desember1.column4 = response.total012_new;
        this.desember1.column6 = response.total012_old;
        this.desember1.column8 = response.total_15_new;
        this.desember1.column10 = response.total_15_old;
        this.desember1.column16 = response.total_cadre;
        this.desember1.column22 = response.total_toddler_safe;
        this.desember1.column24 = response.total_toddler_die;
      }
    },
    getAllReportTable1() {
      this.getAllReportTable1Male("01");
      this.getAllReportTable1Male("02");
      this.getAllReportTable1Male("03");
      this.getAllReportTable1Male("04");
      this.getAllReportTable1Male("05");
      this.getAllReportTable1Male("06");
      this.getAllReportTable1Male("07");
      this.getAllReportTable1Male("08");
      this.getAllReportTable1Male("09");
      this.getAllReportTable1Male("10");
      this.getAllReportTable1Male("11");
      this.getAllReportTable1Male("12");
      this.getAllReportTable1Female("01");
      this.getAllReportTable1Female("02");
      this.getAllReportTable1Female("03");
      this.getAllReportTable1Female("04");
      this.getAllReportTable1Female("05");
      this.getAllReportTable1Female("06");
      this.getAllReportTable1Female("07");
      this.getAllReportTable1Female("08");
      this.getAllReportTable1Female("09");
      this.getAllReportTable1Female("10");
      this.getAllReportTable1Female("11");
      this.getAllReportTable1Female("12");
    },
    // table 2
    async getAllReportTable2SeptemberMale(month) {
      let year = new Date().toISOString().substr(0, 4);
      let response = await module.getReport(
        "api/all-report/table2/" + this.filter.posyandu_id,
        `?month=${this.filter.year}-${month}&gender=male`
      );
      if (month == "01") {
        this.januari2.column3 = response.total_pregnant;
        this.januari2.column4 = response.total_checked;
        this.januari2.column5 = response.total_tablet;
        this.januari2.column6 = response.total_breestfeeding;
        this.januari2.column15 = response.total_toddler;
        this.januari2.column17 = response.total_toddler_is_kms;
        this.januari2.column19 = response.total_p7;
        this.januari2.column21 = response.total_p3;
        this.januari2.column23 = response.total_p12;
        this.januari2.column25 = response.total_toddler_is_pmt;
      } else if (month == "02") {
        this.februari2.column3 = response.total_pregnant;
        this.februari2.column4 = response.total_checked;
        this.februari2.column5 = response.total_tablet;
        this.februari2.column6 = response.total_breestfeeding;
        this.februari2.column15 = response.total_toddler;
        this.februari2.column17 = response.total_toddler_is_kms;
        this.februari2.column19 = response.total_p7;
        this.februari2.column21 = response.total_p3;
        this.februari2.column23 = response.total_p12;
        this.februari2.column25 = response.total_toddler_is_pmt;
      } else if (month == "03") {
        this.maret2.column3 = response.total_pregnant;
        this.maret2.column4 = response.total_checked;
        this.maret2.column5 = response.total_tablet;
        this.maret2.column6 = response.total_breestfeeding;
        this.maret2.column15 = response.total_toddler;
        this.maret2.column17 = response.total_toddler_is_kms;
        this.maret2.column19 = response.total_p7;
        this.maret2.column21 = response.total_p3;
        this.maret2.column23 = response.total_p12;
        this.maret2.column25 = response.total_toddler_is_pmt;
      } else if (month == "04") {
        this.april2.column3 = response.total_pregnant;
        this.april2.column4 = response.total_checked;
        this.april2.column5 = response.total_tablet;
        this.april2.column6 = response.total_breestfeeding;
        this.april2.column15 = response.total_toddler;
        this.april2.column17 = response.total_toddler_is_kms;
        this.april2.column19 = response.total_p7;
        this.april2.column21 = response.total_p3;
        this.april2.column23 = response.total_p12;
        this.april2.column25 = response.total_toddler_is_pmt;
      } else if (month == "05") {
        this.mei2.column3 = response.total_pregnant;
        this.mei2.column4 = response.total_checked;
        this.mei2.column5 = response.total_tablet;
        this.mei2.column6 = response.total_breestfeeding;
        this.mei2.column15 = response.total_toddler;
        this.mei2.column17 = response.total_toddler_is_kms;
        this.mei2.column19 = response.total_p7;
        this.mei2.column21 = response.total_p3;
        this.mei2.column23 = response.total_p12;
        this.mei2.column25 = response.total_toddler_is_pmt;
      } else if (month == "06") {
        this.juni2.column3 = response.total_pregnant;
        this.juni2.column4 = response.total_checked;
        this.juni2.column5 = response.total_tablet;
        this.juni2.column6 = response.total_breestfeeding;
        this.juni2.column15 = response.total_toddler;
        this.juni2.column17 = response.total_toddler_is_kms;
        this.juni2.column19 = response.total_p7;
        this.juni2.column21 = response.total_p3;
        this.juni2.column23 = response.total_p12;
        this.juni2.column25 = response.total_toddler_is_pmt;
      } else if (month == "07") {
        this.juli2.column3 = response.total_pregnant;
        this.juli2.column4 = response.total_checked;
        this.juli2.column5 = response.total_tablet;
        this.juli2.column6 = response.total_breestfeeding;
        this.juli2.column15 = response.total_toddler;
        this.juli2.column17 = response.total_toddler_is_kms;
        this.juli2.column19 = response.total_p7;
        this.juli2.column21 = response.total_p3;
        this.juli2.column23 = response.total_p12;
        this.juli2.column25 = response.total_toddler_is_pmt;
      } else if (month == "08") {
        this.agustus2.column3 = response.total_pregnant;
        this.agustus2.column4 = response.total_checked;
        this.agustus2.column5 = response.total_tablet;
        this.agustus2.column6 = response.total_breestfeeding;
        this.agustus2.column15 = response.total_toddler;
        this.agustus2.column17 = response.total_toddler_is_kms;
        this.agustus2.column19 = response.total_p7;
        this.agustus2.column21 = response.total_p3;
        this.agustus2.column23 = response.total_p12;
        this.agustus2.column25 = response.total_toddler_is_pmt;
      } else if (month == "09") {
        this.september2.column3 = response.total_pregnant;
        this.september2.column4 = response.total_checked;
        this.september2.column5 = response.total_tablet;
        this.september2.column6 = response.total_breestfeeding;
        this.september2.column15 = response.total_toddler;
        this.september2.column17 = response.total_toddler_is_kms;
        this.september2.column19 = response.total_p7;
        this.september2.column21 = response.total_p3;
        this.september2.column23 = response.total_p12;
        this.september2.column25 = response.total_toddler_is_pmt;
      } else if (month == "10") {
        this.oktober2.column3 = response.total_pregnant;
        this.oktober2.column4 = response.total_checked;
        this.oktober2.column5 = response.total_tablet;
        this.oktober2.column6 = response.total_breestfeeding;
        this.oktober2.column15 = response.total_toddler;
        this.oktober2.column17 = response.total_toddler_is_kms;
        this.oktober2.column19 = response.total_p7;
        this.oktober2.column21 = response.total_p3;
        this.oktober2.column23 = response.total_p12;
        this.oktober2.column25 = response.total_toddler_is_pmt;
      } else if (month == "11") {
        this.november2.column3 = response.total_pregnant;
        this.november2.column4 = response.total_checked;
        this.november2.column5 = response.total_tablet;
        this.november2.column6 = response.total_breestfeeding;
        this.november2.column15 = response.total_toddler;
        this.november2.column17 = response.total_toddler_is_kms;
        this.november2.column19 = response.total_p7;
        this.november2.column21 = response.total_p3;
        this.november2.column23 = response.total_p12;
        this.november2.column25 = response.total_toddler_is_pmt;
      } else if (month == "12") {
        this.desember2.column3 = response.total_pregnant;
        this.desember2.column4 = response.total_checked;
        this.desember2.column5 = response.total_tablet;
        this.desember2.column6 = response.total_breestfeeding;
        this.desember2.column15 = response.total_toddler;
        this.desember2.column17 = response.total_toddler_is_kms;
        this.desember2.column19 = response.total_p7;
        this.desember2.column21 = response.total_p3;
        this.desember2.column23 = response.total_p12;
        this.desember2.column25 = response.total_toddler_is_pmt;
      }
    },
    async getAllReportTable2SeptemberFemale(month) {
      let year = new Date().toISOString().substr(0, 4);
      let response = await module.getReport(
        "api/all-report/table2/" + this.filter.posyandu_id,
        `?month=${this.filter.year}-${month}&gender=female`
      );

      if (month == "01") {
        this.januari2.column16 = response.total_toddler;
        this.januari2.column18 = response.total_toddler_is_kms;
        this.januari2.column20 = response.total_p7;
        this.januari2.column22 = response.total_p3;
        this.januari2.column24 = response.total_p12;
        this.januari2.column26 = response.total_toddler_is_pmt;
      } else if (month == "02") {
        this.februari2.column16 = response.total_toddler;
        this.februari2.column18 = response.total_toddler_is_kms;
        this.februari2.column20 = response.total_p7;
        this.februari2.column22 = response.total_p3;
        this.februari2.column24 = response.total_p12;
        this.februari2.column26 = response.total_toddler_is_pmt;
      } else if (month == "03") {
        this.maret2.column16 = response.total_toddler;
        this.maret2.column18 = response.total_toddler_is_kms;
        this.maret2.column20 = response.total_p7;
        this.maret2.column22 = response.total_p3;
        this.maret2.column24 = response.total_p12;
        this.maret2.column26 = response.total_toddler_is_pmt;
      } else if (month == "04") {
        this.april2.column16 = response.total_toddler;
        this.april2.column18 = response.total_toddler_is_kms;
        this.april2.column20 = response.total_p7;
        this.april2.column22 = response.total_p3;
        this.april2.column24 = response.total_p12;
        this.april2.column26 = response.total_toddler_is_pmt;
      } else if (month == "05") {
        this.mei2.column16 = response.total_toddler;
        this.mei2.column18 = response.total_toddler_is_kms;
        this.mei2.column20 = response.total_p7;
        this.mei2.column22 = response.total_p3;
        this.mei2.column24 = response.total_p12;
        this.mei2.column26 = response.total_toddler_is_pmt;
      } else if (month == "06") {
        this.juni2.column16 = response.total_toddler;
        this.juni2.column18 = response.total_toddler_is_kms;
        this.juni2.column20 = response.total_p7;
        this.juni2.column22 = response.total_p3;
        this.juni2.column24 = response.total_p12;
        this.juni2.column26 = response.total_toddler_is_pmt;
      } else if (month == "07") {
        this.juli2.column16 = response.total_toddler;
        this.juli2.column18 = response.total_toddler_is_kms;
        this.juli2.column20 = response.total_p7;
        this.juli2.column22 = response.total_p3;
        this.juli2.column24 = response.total_p12;
        this.juli2.column26 = response.total_toddler_is_pmt;
      } else if (month == "08") {
        this.agustus2.column16 = response.total_toddler;
        this.agustus2.column18 = response.total_toddler_is_kms;
        this.agustus2.column20 = response.total_p7;
        this.agustus2.column22 = response.total_p3;
        this.agustus2.column24 = response.total_p12;
        this.agustus2.column26 = response.total_toddler_is_pmt;
      } else if (month == "09") {
        this.september2.column16 = response.total_toddler;
        this.september2.column18 = response.total_toddler_is_kms;
        this.september2.column20 = response.total_p7;
        this.september2.column22 = response.total_p3;
        this.september2.column24 = response.total_p12;
        this.september2.column26 = response.total_toddler_is_pmt;
      } else if (month == "10") {
        this.oktober2.column16 = response.total_toddler;
        this.oktober2.column18 = response.total_toddler_is_kms;
        this.oktober2.column20 = response.total_p7;
        this.oktober2.column22 = response.total_p3;
        this.oktober2.column24 = response.total_p12;
        this.oktober2.column26 = response.total_toddler_is_pmt;
      } else if (month == "11") {
        this.november2.column16 = response.total_toddler;
        this.november2.column18 = response.total_toddler_is_kms;
        this.november2.column20 = response.total_p7;
        this.november2.column22 = response.total_p3;
        this.november2.column24 = response.total_p12;
        this.november2.column26 = response.total_toddler_is_pmt;
      } else if (month == "12") {
        this.desember2.column16 = response.total_toddler;
        this.desember2.column18 = response.total_toddler_is_kms;
        this.desember2.column20 = response.total_p7;
        this.desember2.column22 = response.total_p3;
        this.desember2.column24 = response.total_p12;
        this.desember2.column26 = response.total_toddler_is_pmt;
      }

      console.log("female", response);
    },
    async getTotalAcceptor(month, acceptor_type_id) {
      let year = new Date().toISOString().substr(0, 4);
      let response = await module.getReport(
        "api/mothers/total-acceptor/" + this.filter.posyandu_id,
        `?month=${this.filter.year}-${month}&acceptor_type_id=${acceptor_type_id}`
      );

      if (response == null) {
        response = "-";
      }

      if (month == "09") {
        if (acceptor_type_id == 1) {
          this.september2.column7 = response;
        } else if (acceptor_type_id == 2) {
          this.september2.column8 = response;
        } else if (acceptor_type_id == 3) {
          this.september2.column9 = response;
        } else if (acceptor_type_id == 4) {
          this.september2.column10 = response;
        } else if (acceptor_type_id == 5) {
          this.september2.column11 = response;
        } else if (acceptor_type_id == 6) {
          this.september2.column12 = response;
        } else if (acceptor_type_id == 7) {
          this.september2.column13 = response;
        } else {
          this.september2.column14 = response;
        }
      }
      console.log("acceptor", response);
    },
    getAcceptorTable2() {
      this.getTotalAcceptor("01", 1);
      this.getTotalAcceptor("01", 2);
      this.getTotalAcceptor("01", 3);
      this.getTotalAcceptor("01", 4);
      this.getTotalAcceptor("01", 5);
      this.getTotalAcceptor("01", 6);
      this.getTotalAcceptor("01", 7);
      this.getTotalAcceptor("01", -1);
      this.getTotalAcceptor("02", 1);
      this.getTotalAcceptor("02", 2);
      this.getTotalAcceptor("02", 3);
      this.getTotalAcceptor("02", 4);
      this.getTotalAcceptor("02", 5);
      this.getTotalAcceptor("02", 6);
      this.getTotalAcceptor("02", 7);
      this.getTotalAcceptor("02", -1);
      this.getTotalAcceptor("03", 1);
      this.getTotalAcceptor("03", 2);
      this.getTotalAcceptor("03", 3);
      this.getTotalAcceptor("03", 4);
      this.getTotalAcceptor("03", 5);
      this.getTotalAcceptor("03", 6);
      this.getTotalAcceptor("03", 7);
      this.getTotalAcceptor("03", -1);
      this.getTotalAcceptor("04", 1);
      this.getTotalAcceptor("04", 2);
      this.getTotalAcceptor("04", 3);
      this.getTotalAcceptor("04", 4);
      this.getTotalAcceptor("04", 5);
      this.getTotalAcceptor("04", 6);
      this.getTotalAcceptor("04", 7);
      this.getTotalAcceptor("04", -1);
      this.getTotalAcceptor("05", 1);
      this.getTotalAcceptor("05", 2);
      this.getTotalAcceptor("05", 3);
      this.getTotalAcceptor("05", 4);
      this.getTotalAcceptor("05", 5);
      this.getTotalAcceptor("05", 6);
      this.getTotalAcceptor("05", 7);
      this.getTotalAcceptor("05", -1);
      this.getTotalAcceptor("06", 1);
      this.getTotalAcceptor("06", 2);
      this.getTotalAcceptor("06", 3);
      this.getTotalAcceptor("06", 4);
      this.getTotalAcceptor("06", 5);
      this.getTotalAcceptor("06", 6);
      this.getTotalAcceptor("06", 7);
      this.getTotalAcceptor("06", -1);
      this.getTotalAcceptor("07", 1);
      this.getTotalAcceptor("07", 2);
      this.getTotalAcceptor("07", 3);
      this.getTotalAcceptor("07", 4);
      this.getTotalAcceptor("07", 5);
      this.getTotalAcceptor("07", 6);
      this.getTotalAcceptor("07", 7);
      this.getTotalAcceptor("07", -1);
      this.getTotalAcceptor("08", 1);
      this.getTotalAcceptor("08", 2);
      this.getTotalAcceptor("08", 3);
      this.getTotalAcceptor("08", 4);
      this.getTotalAcceptor("08", 5);
      this.getTotalAcceptor("08", 6);
      this.getTotalAcceptor("08", 7);
      this.getTotalAcceptor("08", -1);
      this.getTotalAcceptor("09", 1);
      this.getTotalAcceptor("09", 2);
      this.getTotalAcceptor("09", 3);
      this.getTotalAcceptor("09", 4);
      this.getTotalAcceptor("09", 5);
      this.getTotalAcceptor("09", 6);
      this.getTotalAcceptor("09", 7);
      this.getTotalAcceptor("09", -1);
      this.getTotalAcceptor("10", 1);
      this.getTotalAcceptor("10", 2);
      this.getTotalAcceptor("10", 3);
      this.getTotalAcceptor("10", 4);
      this.getTotalAcceptor("10", 5);
      this.getTotalAcceptor("10", 6);
      this.getTotalAcceptor("10", 7);
      this.getTotalAcceptor("10", -1);
      this.getTotalAcceptor("11", 1);
      this.getTotalAcceptor("11", 2);
      this.getTotalAcceptor("11", 3);
      this.getTotalAcceptor("11", 4);
      this.getTotalAcceptor("11", 5);
      this.getTotalAcceptor("11", 6);
      this.getTotalAcceptor("11", 7);
      this.getTotalAcceptor("11", -1);
      this.getTotalAcceptor("12", 1);
      this.getTotalAcceptor("12", 2);
      this.getTotalAcceptor("12", 3);
      this.getTotalAcceptor("12", 4);
      this.getTotalAcceptor("12", 5);
      this.getTotalAcceptor("12", 6);
      this.getTotalAcceptor("12", 7);
      this.getTotalAcceptor("12", -1);
    },
    getReportTable2() {
      this.getAllReportTable2SeptemberMale("01");
      this.getAllReportTable2SeptemberFemale("01");
      this.getAllReportTable2SeptemberMale("02");
      this.getAllReportTable2SeptemberFemale("02");
      this.getAllReportTable2SeptemberMale("03");
      this.getAllReportTable2SeptemberFemale("03");
      this.getAllReportTable2SeptemberMale("04");
      this.getAllReportTable2SeptemberFemale("04");
      this.getAllReportTable2SeptemberMale("05");
      this.getAllReportTable2SeptemberFemale("05");
      this.getAllReportTable2SeptemberMale("06");
      this.getAllReportTable2SeptemberFemale("06");
      this.getAllReportTable2SeptemberMale("07");
      this.getAllReportTable2SeptemberFemale("07");
      this.getAllReportTable2SeptemberMale("08");
      this.getAllReportTable2SeptemberFemale("08");
      this.getAllReportTable2SeptemberMale("09");
      this.getAllReportTable2SeptemberFemale("09");
      this.getAllReportTable2SeptemberMale("10");
      this.getAllReportTable2SeptemberFemale("10");
      this.getAllReportTable2SeptemberMale("11");
      this.getAllReportTable2SeptemberFemale("11");
      this.getAllReportTable2SeptemberMale("12");
      this.getAllReportTable2SeptemberFemale("12");
    },
    // table 3
    async getAllReportTable3Male(month) {
      let year = new Date().toISOString().substr(0, 4);
      let response = await module.getReport(
        "api/all-report/table3/" + this.filter.posyandu_id,
        `?month=${this.filter.year}-${month}&gender=male`
      );
      if (month == "01") {
        this.januari3.column3 = response.total_tt1;
        this.januari3.column4 = response.total_tt2;
        this.januari3.column5 = response.total_bcg;
        this.januari3.column7 = response.total_dpt1;
        this.januari3.column9 = response.total_dpt2;
        this.januari3.column11 = response.total_dpt3;
        this.januari3.column13 = response.total_polio1;
        this.januari3.column15 = response.total_polio2;
        this.januari3.column17 = response.total_polio3;
        this.januari3.column19 = response.total_polio4;
        this.januari3.column21 = response.total_campak;
        this.januari3.column23 = response.total_hepatitis_b1;
        this.januari3.column25 = response.total_hepatitis_b2;
        this.januari3.column27 = response.total_hepatitis_b3;
        this.januari3.column31 = response.total_oralit;
      } else if (month == "02") {
        this.februari3.column3 = response.total_tt1;
        this.februari3.column4 = response.total_tt2;
        this.februari3.column5 = response.total_bcg;
        this.februari3.column7 = response.total_dpt1;
        this.februari3.column9 = response.total_dpt2;
        this.februari3.column11 = response.total_dpt3;
        this.februari3.column13 = response.total_polio1;
        this.februari3.column15 = response.total_polio2;
        this.februari3.column17 = response.total_polio3;
        this.februari3.column19 = response.total_polio4;
        this.februari3.column21 = response.total_campak;
        this.februari3.column23 = response.total_hepatitis_b1;
        this.februari3.column25 = response.total_hepatitis_b2;
        this.februari3.column27 = response.total_hepatitis_b3;
        this.februari3.column31 = response.total_oralit;
      } else if (month == "03") {
        this.maret3.column3 = response.total_tt1;
        this.maret3.column4 = response.total_tt2;
        this.maret3.column5 = response.total_bcg;
        this.maret3.column7 = response.total_dpt1;
        this.maret3.column9 = response.total_dpt2;
        this.maret3.column11 = response.total_dpt3;
        this.maret3.column13 = response.total_polio1;
        this.maret3.column15 = response.total_polio2;
        this.maret3.column17 = response.total_polio3;
        this.maret3.column19 = response.total_polio4;
        this.maret3.column21 = response.total_campak;
        this.maret3.column23 = response.total_hepatitis_b1;
        this.maret3.column25 = response.total_hepatitis_b2;
        this.maret3.column27 = response.total_hepatitis_b3;
        this.maret3.column31 = response.total_oralit;
      } else if (month == "04") {
        this.april3.column3 = response.total_tt1;
        this.april3.column4 = response.total_tt2;
        this.april3.column5 = response.total_bcg;
        this.april3.column7 = response.total_dpt1;
        this.april3.column9 = response.total_dpt2;
        this.april3.column11 = response.total_dpt3;
        this.april3.column13 = response.total_polio1;
        this.april3.column15 = response.total_polio2;
        this.april3.column17 = response.total_polio3;
        this.april3.column19 = response.total_polio4;
        this.april3.column21 = response.total_campak;
        this.april3.column23 = response.total_hepatitis_b1;
        this.april3.column25 = response.total_hepatitis_b2;
        this.april3.column27 = response.total_hepatitis_b3;
        this.april3.column31 = response.total_oralit;
      } else if (month == "05") {
        this.mei3.column3 = response.total_tt1;
        this.mei3.column4 = response.total_tt2;
        this.mei3.column5 = response.total_bcg;
        this.mei3.column7 = response.total_dpt1;
        this.mei3.column9 = response.total_dpt2;
        this.mei3.column11 = response.total_dpt3;
        this.mei3.column13 = response.total_polio1;
        this.mei3.column15 = response.total_polio2;
        this.mei3.column17 = response.total_polio3;
        this.mei3.column19 = response.total_polio4;
        this.mei3.column21 = response.total_campak;
        this.mei3.column23 = response.total_hepatitis_b1;
        this.mei3.column25 = response.total_hepatitis_b2;
        this.mei3.column27 = response.total_hepatitis_b3;
        this.mei3.column31 = response.total_oralit;
      } else if (month == "06") {
        this.juni3.column3 = response.total_tt1;
        this.juni3.column4 = response.total_tt2;
        this.juni3.column5 = response.total_bcg;
        this.juni3.column7 = response.total_dpt1;
        this.juni3.column9 = response.total_dpt2;
        this.juni3.column11 = response.total_dpt3;
        this.juni3.column13 = response.total_polio1;
        this.juni3.column15 = response.total_polio2;
        this.juni3.column17 = response.total_polio3;
        this.juni3.column19 = response.total_polio4;
        this.juni3.column21 = response.total_campak;
        this.juni3.column23 = response.total_hepatitis_b1;
        this.juni3.column25 = response.total_hepatitis_b2;
        this.juni3.column27 = response.total_hepatitis_b3;
        this.juni3.column31 = response.total_oralit;
      } else if (month == "07") {
        this.juli3.column3 = response.total_tt1;
        this.juli3.column4 = response.total_tt2;
        this.juli3.column5 = response.total_bcg;
        this.juli3.column7 = response.total_dpt1;
        this.juli3.column9 = response.total_dpt2;
        this.juli3.column11 = response.total_dpt3;
        this.juli3.column13 = response.total_polio1;
        this.juli3.column15 = response.total_polio2;
        this.juli3.column17 = response.total_polio3;
        this.juli3.column19 = response.total_polio4;
        this.juli3.column21 = response.total_campak;
        this.juli3.column23 = response.total_hepatitis_b1;
        this.juli3.column25 = response.total_hepatitis_b2;
        this.juli3.column27 = response.total_hepatitis_b3;
        this.juli3.column31 = response.total_oralit;
      } else if (month == "08") {
        this.agustus3.column3 = response.total_tt1;
        this.agustus3.column4 = response.total_tt2;
        this.agustus3.column5 = response.total_bcg;
        this.agustus3.column7 = response.total_dpt1;
        this.agustus3.column9 = response.total_dpt2;
        this.agustus3.column11 = response.total_dpt3;
        this.agustus3.column13 = response.total_polio1;
        this.agustus3.column15 = response.total_polio2;
        this.agustus3.column17 = response.total_polio3;
        this.agustus3.column19 = response.total_polio4;
        this.agustus3.column21 = response.total_campak;
        this.agustus3.column23 = response.total_hepatitis_b1;
        this.agustus3.column25 = response.total_hepatitis_b2;
        this.agustus3.column27 = response.total_hepatitis_b3;
        this.agustus3.column31 = response.total_oralit;
      } else if (month == "09") {
        this.september3.column3 = response.total_tt1;
        this.september3.column4 = response.total_tt2;
        this.september3.column5 = response.total_bcg;
        this.september3.column7 = response.total_dpt1;
        this.september3.column9 = response.total_dpt2;
        this.september3.column11 = response.total_dpt3;
        this.september3.column13 = response.total_polio1;
        this.september3.column15 = response.total_polio2;
        this.september3.column17 = response.total_polio3;
        this.september3.column19 = response.total_polio4;
        this.september3.column21 = response.total_campak;
        this.september3.column23 = response.total_hepatitis_b1;
        this.september3.column25 = response.total_hepatitis_b2;
        this.september3.column27 = response.total_hepatitis_b3;
        this.september3.column31 = response.total_oralit;
      } else if (month == "10") {
        this.oktober3.column3 = response.total_tt1;
        this.oktober3.column4 = response.total_tt2;
        this.oktober3.column5 = response.total_bcg;
        this.oktober3.column7 = response.total_dpt1;
        this.oktober3.column9 = response.total_dpt2;
        this.oktober3.column11 = response.total_dpt3;
        this.oktober3.column13 = response.total_polio1;
        this.oktober3.column15 = response.total_polio2;
        this.oktober3.column17 = response.total_polio3;
        this.oktober3.column19 = response.total_polio4;
        this.oktober3.column21 = response.total_campak;
        this.oktober3.column23 = response.total_hepatitis_b1;
        this.oktober3.column25 = response.total_hepatitis_b2;
        this.oktober3.column27 = response.total_hepatitis_b3;
        this.oktober3.column31 = response.total_oralit;
      } else if (month == "11") {
        this.november3.column3 = response.total_tt1;
        this.november3.column4 = response.total_tt2;
        this.november3.column5 = response.total_bcg;
        this.november3.column7 = response.total_dpt1;
        this.november3.column9 = response.total_dpt2;
        this.november3.column11 = response.total_dpt3;
        this.november3.column13 = response.total_polio1;
        this.november3.column15 = response.total_polio2;
        this.november3.column17 = response.total_polio3;
        this.november3.column19 = response.total_polio4;
        this.november3.column21 = response.total_campak;
        this.november3.column23 = response.total_hepatitis_b1;
        this.november3.column25 = response.total_hepatitis_b2;
        this.november3.column27 = response.total_hepatitis_b3;
        this.november3.column31 = response.total_oralit;
      } else if (month == "12") {
        this.desember3.column3 = response.total_tt1;
        this.desember3.column4 = response.total_tt2;
        this.desember3.column5 = response.total_bcg;
        this.desember3.column7 = response.total_dpt1;
        this.desember3.column9 = response.total_dpt2;
        this.desember3.column11 = response.total_dpt3;
        this.desember3.column13 = response.total_polio1;
        this.desember3.column15 = response.total_polio2;
        this.desember3.column17 = response.total_polio3;
        this.desember3.column19 = response.total_polio4;
        this.desember3.column21 = response.total_campak;
        this.desember3.column23 = response.total_hepatitis_b1;
        this.desember3.column25 = response.total_hepatitis_b2;
        this.desember3.column27 = response.total_hepatitis_b3;
        this.desember3.column31 = response.total_oralit;
      }
    },
    async getAllReportTable3Female(month) {
      let year = new Date().toISOString().substr(0, 4);
      let response = await module.getReport(
        "api/all-report/table3/" + this.filter.posyandu_id,
        `?month=${this.filter.year}-${month}&gender=female`
      );
      if (month == "01") {
        this.januari3.column6 = response.total_bcg;
        this.januari3.column8 = response.total_dpt1;
        this.januari3.column10 = response.total_dpt2;
        this.januari3.column12 = response.total_dpt3;
        this.januari3.column14 = response.total_polio1;
        this.januari3.column16 = response.total_polio2;
        this.januari3.column18 = response.total_polio3;
        this.januari3.column20 = response.total_polio4;
        this.januari3.column22 = response.total_campak;
        this.januari3.column24 = response.total_hepatitis_b1;
        this.januari3.column26 = response.total_hepatitis_b2;
        this.januari3.column28 = response.total_hepatitis_b3;
        this.januari3.column32 = response.total_oralit;
      } else if (month == "02") {
        this.februari3.column6 = response.total_bcg;
        this.februari3.column8 = response.total_dpt1;
        this.februari3.column10 = response.total_dpt2;
        this.februari3.column12 = response.total_dpt3;
        this.februari3.column14 = response.total_polio1;
        this.februari3.column16 = response.total_polio2;
        this.februari3.column18 = response.total_polio3;
        this.februari3.column20 = response.total_polio4;
        this.februari3.column22 = response.total_campak;
        this.februari3.column24 = response.total_hepatitis_b1;
        this.februari3.column26 = response.total_hepatitis_b2;
        this.februari3.column28 = response.total_hepatitis_b3;
        this.februari3.column32 = response.total_oralit;
      } else if (month == "03") {
        this.maret3.column6 = response.total_bcg;
        this.maret3.column8 = response.total_dpt1;
        this.maret3.column10 = response.total_dpt2;
        this.maret3.column12 = response.total_dpt3;
        this.maret3.column14 = response.total_polio1;
        this.maret3.column16 = response.total_polio2;
        this.maret3.column18 = response.total_polio3;
        this.maret3.column20 = response.total_polio4;
        this.maret3.column22 = response.total_campak;
        this.maret3.column24 = response.total_hepatitis_b1;
        this.maret3.column26 = response.total_hepatitis_b2;
        this.maret3.column28 = response.total_hepatitis_b3;
        this.maret3.column32 = response.total_oralit;
      } else if (month == "04") {
        this.april3.column6 = response.total_bcg;
        this.april3.column8 = response.total_dpt1;
        this.april3.column10 = response.total_dpt2;
        this.april3.column12 = response.total_dpt3;
        this.april3.column14 = response.total_polio1;
        this.april3.column16 = response.total_polio2;
        this.april3.column18 = response.total_polio3;
        this.april3.column20 = response.total_polio4;
        this.april3.column22 = response.total_campak;
        this.april3.column24 = response.total_hepatitis_b1;
        this.april3.column26 = response.total_hepatitis_b2;
        this.april3.column28 = response.total_hepatitis_b3;
        this.april3.column32 = response.total_oralit;
      } else if (month == "05") {
        this.mei3.column6 = response.total_bcg;
        this.mei3.column8 = response.total_dpt1;
        this.mei3.column10 = response.total_dpt2;
        this.mei3.column12 = response.total_dpt3;
        this.mei3.column14 = response.total_polio1;
        this.mei3.column16 = response.total_polio2;
        this.mei3.column18 = response.total_polio3;
        this.mei3.column20 = response.total_polio4;
        this.mei3.column22 = response.total_campak;
        this.mei3.column24 = response.total_hepatitis_b1;
        this.mei3.column26 = response.total_hepatitis_b2;
        this.mei3.column28 = response.total_hepatitis_b3;
        this.mei3.column32 = response.total_oralit;
      } else if (month == "06") {
        this.juni3.column6 = response.total_bcg;
        this.juni3.column8 = response.total_dpt1;
        this.juni3.column10 = response.total_dpt2;
        this.juni3.column12 = response.total_dpt3;
        this.juni3.column14 = response.total_polio1;
        this.juni3.column16 = response.total_polio2;
        this.juni3.column18 = response.total_polio3;
        this.juni3.column20 = response.total_polio4;
        this.juni3.column22 = response.total_campak;
        this.juni3.column24 = response.total_hepatitis_b1;
        this.juni3.column26 = response.total_hepatitis_b2;
        this.juni3.column28 = response.total_hepatitis_b3;
        this.juni3.column32 = response.total_oralit;
      } else if (month == "07") {
        this.juli3.column6 = response.total_bcg;
        this.juli3.column8 = response.total_dpt1;
        this.juli3.column10 = response.total_dpt2;
        this.juli3.column12 = response.total_dpt3;
        this.juli3.column14 = response.total_polio1;
        this.juli3.column16 = response.total_polio2;
        this.juli3.column18 = response.total_polio3;
        this.juli3.column20 = response.total_polio4;
        this.juli3.column22 = response.total_campak;
        this.juli3.column24 = response.total_hepatitis_b1;
        this.juli3.column26 = response.total_hepatitis_b2;
        this.juli3.column28 = response.total_hepatitis_b3;
        this.juli3.column32 = response.total_oralit;
      } else if (month == "08") {
        this.agustus3.column6 = response.total_bcg;
        this.agustus3.column8 = response.total_dpt1;
        this.agustus3.column10 = response.total_dpt2;
        this.agustus3.column12 = response.total_dpt3;
        this.agustus3.column14 = response.total_polio1;
        this.agustus3.column16 = response.total_polio2;
        this.agustus3.column18 = response.total_polio3;
        this.agustus3.column20 = response.total_polio4;
        this.agustus3.column22 = response.total_campak;
        this.agustus3.column24 = response.total_hepatitis_b1;
        this.agustus3.column26 = response.total_hepatitis_b2;
        this.agustus3.column28 = response.total_hepatitis_b3;
        this.agustus3.column32 = response.total_oralit;
      } else if (month == "09") {
        this.september3.column6 = response.total_bcg;
        this.september3.column8 = response.total_dpt1;
        this.september3.column10 = response.total_dpt2;
        this.september3.column12 = response.total_dpt3;
        this.september3.column14 = response.total_polio1;
        this.september3.column16 = response.total_polio2;
        this.september3.column18 = response.total_polio3;
        this.september3.column20 = response.total_polio4;
        this.september3.column22 = response.total_campak;
        this.september3.column24 = response.total_hepatitis_b1;
        this.september3.column26 = response.total_hepatitis_b2;
        this.september3.column28 = response.total_hepatitis_b3;
        this.september3.column32 = response.total_oralit;
      } else if (month == "10") {
        this.oktober3.column6 = response.total_bcg;
        this.oktober3.column8 = response.total_dpt1;
        this.oktober3.column10 = response.total_dpt2;
        this.oktober3.column12 = response.total_dpt3;
        this.oktober3.column14 = response.total_polio1;
        this.oktober3.column16 = response.total_polio2;
        this.oktober3.column18 = response.total_polio3;
        this.oktober3.column20 = response.total_polio4;
        this.oktober3.column22 = response.total_campak;
        this.oktober3.column24 = response.total_hepatitis_b1;
        this.oktober3.column26 = response.total_hepatitis_b2;
        this.oktober3.column28 = response.total_hepatitis_b3;
        this.oktober3.column32 = response.total_oralit;
      } else if (month == "11") {
        this.november3.column6 = response.total_bcg;
        this.november3.column8 = response.total_dpt1;
        this.november3.column10 = response.total_dpt2;
        this.november3.column12 = response.total_dpt3;
        this.november3.column14 = response.total_polio1;
        this.november3.column16 = response.total_polio2;
        this.november3.column18 = response.total_polio3;
        this.november3.column20 = response.total_polio4;
        this.november3.column22 = response.total_campak;
        this.november3.column24 = response.total_hepatitis_b1;
        this.november3.column26 = response.total_hepatitis_b2;
        this.november3.column28 = response.total_hepatitis_b3;
        this.november3.column32 = response.total_oralit;
      } else if (month == "12") {
        this.desember3.column6 = response.total_bcg;
        this.desember3.column8 = response.total_dpt1;
        this.desember3.column10 = response.total_dpt2;
        this.desember3.column12 = response.total_dpt3;
        this.desember3.column14 = response.total_polio1;
        this.desember3.column16 = response.total_polio2;
        this.desember3.column18 = response.total_polio3;
        this.desember3.column20 = response.total_polio4;
        this.desember3.column22 = response.total_campak;
        this.desember3.column24 = response.total_hepatitis_b1;
        this.desember3.column26 = response.total_hepatitis_b2;
        this.desember3.column28 = response.total_hepatitis_b3;
        this.desember3.column32 = response.total_oralit;
      }
    },
    getReportTable3() {
      this.getAllReportTable3Male("01");
      this.getAllReportTable3Male("02");
      this.getAllReportTable3Male("03");
      this.getAllReportTable3Male("04");
      this.getAllReportTable3Male("05");
      this.getAllReportTable3Male("06");
      this.getAllReportTable3Male("07");
      this.getAllReportTable3Male("08");
      this.getAllReportTable3Male("09");
      this.getAllReportTable3Male("10");
      this.getAllReportTable3Male("11");
      this.getAllReportTable3Male("12");
      this.getAllReportTable3Female("01");
      this.getAllReportTable3Female("02");
      this.getAllReportTable3Female("03");
      this.getAllReportTable3Female("04");
      this.getAllReportTable3Female("05");
      this.getAllReportTable3Female("06");
      this.getAllReportTable3Female("07");
      this.getAllReportTable3Female("08");
      this.getAllReportTable3Female("09");
      this.getAllReportTable3Female("10");
      this.getAllReportTable3Female("11");
      this.getAllReportTable3Female("12");
    },
    async getPosyanduById() {
      this.posyandu = await module.get(
        "api/posyandu/" + this.filter.posyandu_id
      );
    },
    async getPosyanduOption() {
      let response = await module.setTreeSelect("api/posyandu");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.posyandu_list = response.data;
        this.posyandu_list.unshift({
          label: "Pilih Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },
    filterByPosyandu(evt) {
      if (typeof evt != "undefined") {
        this.filter.posyandu_id = evt;
        this.getPosyanduById();
        this.getAllReportTable1();
        this.getReportTable2();
        this.getReportTable3();
        this.getAcceptorTable2();
        this.dataLoaded = true;
        this.buttonPrint = true
      } else {
        this.filter.posyandu_id = "";
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan Pengunjung"}
    ]);
    if (
      getUser().role_key_name != "super_admin" &&
      getUser().user_type_id != 3
    ) {
    } else {
      this.getPosyanduOption();
    }
  },
  watch: {
    modal(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
